<template>
  <div class="row_right">
    <DashboardTitleComponent :title="companyDetails.companyName" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/profile">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
      </router-link>
    </div>
    <section class="company_add_main">
      <div class="form_block">
        <div class="form_row_left">
          <img :src="selectedFileBase64" alt="upload" v-if="selectedFileBase64">
          <img v-else :src="`data:image/png;base64,${companyDetails.logoBase64Encoded}`" alt="upload">
          <p>Company Logo</p>
          <label for="file_upload" class="button">Browse</label>
          <input type="file" id="file_upload" name="file_upload" style="display: none;" @change="processSelectedFile">
        </div>
        <div class="form_row_right">

          <div class="col_form top_company">
            <label for="company">Company name <span>*</span></label>
            <input type="text" id="companyName" name="company" v-model="companyDetails.companyName">
          </div>

          <div class="col_form bot_company">
            <h3>Contact Info</h3>
            <div class="bot_row_form">
              <div class="form_input">
                <label for="">First name <span>*</span></label>
                <input type="text" id="firstName" class="form-control" v-model="userDetails.firstName">
              </div>
              <div class="form_input">
                <label for="">Last name <span>*</span></label>
                <input type="text" id="lastName" class="form-control" v-model="userDetails.lastName">
              </div>
            </div>
            <div class="bot_row_form">
              <div class="form_input">
                <label for="">Email <span>*</span></label>
                <input type="text" id="email" class="form-control" v-model="companyDetails.email">
              </div>
              <div class="form_input">
                <label for="">Phone number <span>*</span></label>
                <input v-maska="['+1 (###) ###-####', '+1 (###) ###-####']" type="text" class="form-control"
                  v-model="companyDetails.phoneNumber" id="phoneNumber">
              </div>
            </div>
            <!-- <a href="#" class="add-new"> <img src="@/assets/images/plus-circle.svg" alt="plus"> <span>Add new</span></a> -->
          </div>
          <div class="btn_container">
            <a class="button" href="#" @click="editCompanyDetails">Submit</a>
          </div>
        </div>

      </div>
    </section>
  </div>
  <div class="overlay hidden"></div>
  <!-- Modal notification -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a class="close btn-close" data-close="modals">⨉</a>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

// services
import companyService from '@/services/companyService';
import userService from '@/services/userService';
import { imageFileAllowedExtensions } from '@/constants';

export default {
  mounted() {
    this.getCompanyDetails();
    this.getCurrentUserProfileData();
  },
  methods: {
    getCompanyDetails: async function () {
      var response = await companyService.getCompanyDetailsWithoutCompanyId();

      if (response.data.successful) {
        this.companyDetails = response.data.item;
      }
    },
    getCurrentUserProfileData: async function () {
      var response = await userService.getCurrentUserProfileData();
      if (response.data.successful) {
        this.userDetails = response.data.item;
      }
    },
    editCompanyDetails: async function () {
      const formData = new FormData();
      formData.append("companyName", document.getElementById("companyName").value);
      formData.append("firstName", document.getElementById("firstName").value);
      formData.append("lastName", document.getElementById("lastName").value);
      formData.append("email", document.getElementById("email").value);
      formData.append("phoneNumber", document.getElementById("phoneNumber").value);
      var profilePicture = document.getElementById("file_upload").files[0];
      if (profilePicture) {
        formData.append("logo", profilePicture);
      }

      var response = await companyService.editCompanyDetails(formData);

      if (response.data.successful) {
        this.toggleModal(this.modal.successIcon, "Successfully saved", "Company data successfully saved");
        this.setLogo();
      } else {
        this.toggleModal(this.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      this.modal.selectedIcon = imgSrc;
      this.modal.title = title;
      this.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    },
    convertFileToBase64Encoded: async function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    processSelectedFile: async function (e) {
      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      let extension = file.name.split(".").pop().toLowerCase();
      if (!imageFileAllowedExtensions.some(x => x.toLowerCase() === extension)) {
        this.toggleModal(this.modal.errorIcon, "Warning", "Invalid extension");
        return;
      }

      if (file.size <= 0) {
        this.toggleModal(this.modal.warningIcon, "Warning", "Invalid size");
        return;
      }

      try {
        let base64Encoded = await this.convertFileToBase64Encoded(file);
        this.selectedFileBase64 = base64Encoded;
      }
      catch (error) {
        this.toggleModal(this.modal.errorIcon, "Something went wrong", "Please try later");
      }
    },
    setLogo: async function () {
      var response = await companyService.getCompanyDetailsWithoutCompanyId();
      if (response.data.successful) {
        var logo = response.data.item.logoBase64Encoded;
        this.$store.commit('setlogo', { logo: logo });
      }
    }
  },
  data() {
    return {
      //Request model
      companyRequestDto: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        logo: '',
      },
      // Response models
      companyDetails: {
        companyId: 0,
        companyName: '',
        contacteeName: '',
        email: '',
        logoBase64Encoded: '',
        subscriptionPlanLogoBase64Encoded: '',
        phoneNumber: '',
        subscription: ''
      },
      userDetails: {
        firstName: '',
        lastName: ''
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      },
      selectedFileBase64: null
    }
  },
  components: { DashboardTitleComponent }
}
</script>

<style ></style>