<template>
  <ol start="1">
    <li class="table-row" @click="onDoubleClick">
    <div class="col col-1">
      {{ driverorder }}
    </div>
    <div class="col col-2">
      <span>{{ fullName }}</span>
    </div>
    <div class="col col-3">
      <span>{{ phoneNumber }}</span>
    </div>
    <div class="col col-4">
      <span v-for="driverType in driverTypes" :key="driverType.id">{{
        driverType.type
      }}</span>
    </div>
    <div class="col col-5">
      <span>{{ createdDate }}</span>
    </div>
    <!-- <div class="col col-6">
      <a
        href="#"
        data-toggle="modals"
        :data-target="`#modals-delete-${id}`"
        v-if="!isDeleted"
      >
        <img src="@/assets/images/trash.svg" alt="" />
      </a>
      <h3 v-if="isDeleted" style="font-size: 16px; line-height: 10px">
        Deleted
      </h3>
    </div> -->

    <div class="modals modals-delete" :id="`modals-delete-${id}`">
      <h3>Are You sure?</h3>
      <div class="modal-footer">
        <button class="button close btn-close" data-close="modals">
          Cancel
        </button>
        <button
          class="button btn-exit"
          @click="deleteDriver"
          data-close="modals"
        >
          Yes
        </button>
      </div>
    </div>
    <!-- </router-link> -->
  </li>
  </ol>
 
  <!-- Modal notification -->
  <div class="modals modal_notification" :id="`modal_${id}`">
    <div class="content_group">
      <img
        :src="require(`@/assets/images/${modal.selectedIcon}`)"
        alt="Notification icon"
      />
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="#" class="close btn-close" data-close="modals">⨉</a>
  </div>
</template>

<script>
  import driverService from "@/services/driverService";

  export default {
    data() {
      return {
        isLoading: false,
        fullPage: true,
        hover: false,
        
        modal: {
          title: "",
          body: "",
          successIcon: "success_icon.svg",
          warningIcon: "warning_icon.svg",
          errorIcon: "error_icon.svg",
          selectedIcon: "success_icon.svg",
        },
      };
    },
    methods: {
      onDoubleClick: function () {
        this.$router.push(`/driver-info/${this.id}`);
      },
      deleteDriver: async function () {
        var self = this;
        var response = await driverService.markLeadDeleted(self.id);
        if (response.data.successful) {
          self.toggleModal(
            self.modal.successIcon,
            "Success",
            "Driver has been deleted"
          );
          setTimeout(function () {
            self.$router.go();
          }, 3000);
        } else {
          self.toggleModal(
            self.modal.errorIcon,
            "Something",
            response.data.errors[0].messages[0]
          );
        }
      },
      toggleModal: function (imgSrc, title, body) {
        var self = this;
        self.modal.selectedIcon = imgSrc;
        self.modal.title = title;
        self.modal.body = body;

        var overlay_modals = document.querySelector(".overlay");
        const toast_modal = document.getElementById("modal_" + self.id);
        toast_modal.classList.add("open");
        overlay_modals.classList.remove("hidden");
        setTimeout(function () {
          toast_modal.classList.remove("open");
          overlay_modals.classList.add("hidden");
        }, 3000);
      },
    },
 
    props: [
      "id",
      "fullName",
      "phoneNumber",
      "driverTypes",
      "createdDate",
      "isDeleted",
      "driverorder"
    ],
  };
</script>

<style scoped>
li:hover {
  cursor: pointer;
}

</style>