import errorHandler from "@/helpers/errorHandler";
import authService from "./authService";

export default {
  getAdvertisements: async function () {
    var instance = await authService.getAuthorizedRequest();

    var response = {};

    await instance
      .get("/api/vacancy/getAdvertisements")
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });

    return response;
  },
  getAdvertisementDetails: async function () {
    var instance = await authService.getAuthorizedRequest();

    var response = {};
    await instance
      .get("/api/vacancy/GetCompanyAdvertisementDetails")
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });

    return response;
  },
  editAdvertisement: async function (editRequestModel) {
    var instance = await authService.getAuthorizedRequest();

    var response = {};

    await instance
      .post(`/api/vacancy/editAdvertisement`, editRequestModel)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });

    return response;
  },
  createVacancy: async function (createVacancyRequestModel) {
    var instance = await authService.getAuthorizedRequest();
    var response = {};

    await instance
      .post(`/api/vacancy/createVacancy`, createVacancyRequestModel)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        if (error.response) {
          response = error.response;
        } else {
          response = await errorHandler.handleRequestError(error);
        }
      });

    return response;
  },
  getVacancyRequirements: async function () {
    var instance = await authService.getAuthorizedRequest();
    var response = {};

    await instance
      .get(`/api/vacancy/getVacancyRequirements`)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        if (error.response) {
          response = error.response;
        } else {
          response = await errorHandler.handleRequestError(error);
        }
      });
    return response;
  },
  editVacancy: async function (editVacancyRequest) {
    var instance = await authService.getAuthorizedRequest();
    var response = {};

    await instance
      .post(`/api/vacancy/editVacancy`, editVacancyRequest)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        if (error.response) {
          response = error.response;
        } else {
          response = await errorHandler.handleRequestError(error);
        }
      });

    return response;
  },
};
