<template>
  <div class="payment_content" :class="{ premium: false }">
    <!-- Top -->
    <div class="payment_top">
      <span>
        <img
          :src="correctImage"
          alt="basic"
        />
        <p>{{ subscription.name }} Plan</p>
      </span>
      <a
        href="#"
        class="button"
        data-toggle="modals"
        data-target="#modals-plan"
      >
        Change plan
      </a>
    </div>

    <!-- Premium middle -->
    <div
      class="payment_middle_premium"
      v-if="subscription.isSponsoredAdvertisement"
    >
      <div class="sponsored_help">
        <p>Sponsored advertisement</p>
        <span>
          <a
            href="#"
            class="button"
            data-toggle="modals"
            data-target="#modals-adver"
          >
            <img src="@/assets/images/help-circle.svg" alt=""
          /></a>
        </span>
      </div>
      <div class="premium_form_input">
        <label for="subjectline">
          Subjectline
          <span>*</span>
        </label>
        <input
          type="text"
          class="form-control"
          v-model="createCheckoutSessionRequest.subject"
        />
      </div>
      <div style="display: flex; flex-direction: row; align-items: end; justify-content: end;">
        <div style="margin-right: 5px;">
        <a
          @click="addJobDetail"
          class="button"
          style="
            cursor: pointer;
            background-color: #26a28f;
            padding: 10px;
            border-radius: 10px;
            color: white;
          "
          >Add a job detail</a
        >
      </div>
      <div>
        <a
          @click="removeJobDetail"
          class="button"
          style="
            cursor: pointer;
            background-color: #ee1111;
            padding: 10px;
            border-radius: 10px;
            color: white;
          "
          >Remove a job detail</a
        >
      </div>
      </div>
      
      <div class="premium_form_input">
        <label>Job details<span>*</span></label>
        <!-- <input class="form-control" type="text" v-model="createCheckoutSessionRequest.jobDetails"> -->
        <input
          v-for="(value, index) in createCheckoutSessionRequest.jobDetails"
          :key="index"
          type="text"
          v-model="createCheckoutSessionRequest.jobDetails[index]"
          class="form-control"
        />
      </div>
    </div>

    <!-- Middle -->
    <div class="payment_middle" v-if="!subscription.isUnlimitedLeads">
      <input type="number" class="form-control" 
      v-model="createCheckoutSessionRequest.leadsCount"
        @blur="v$.createCheckoutSessionRequest.leadsCount.$touch" 
        :class="{ error_border: v$.createCheckoutSessionRequest.leadsCount.$error }"
      >
      <!-- <input
        class="form-control"
        type="number"
        v-model="createCheckoutSessionRequest.leadsCount"
        @blur="v$.createCheckoutSessionRequest.leadsCount.$touch" 
        :class="{ error_border: v$.createCheckoutSessionRequest.leadsCount.$error }"> -->
      <div class="error_class" v-if="v$.createCheckoutSessionRequest.leadsCount.$error"> The value should be between 20 and 50! </div>
      <span>
        Please select the lead numbers Per lead ${{ subscription.costPerLead }}
      </span>
    </div>

    <!-- Bottom -->
    <div class="payment_bottom">
      <span class="cost">
        <span>Total cost:</span>
        <span
          >${{
            subscription.isUnlimitedLeads
              ? subscription.defaultPrice
              : subscription.costPerLead *
                createCheckoutSessionRequest.leadsCount
          }}</span
        >
      </span>
      <!-- data-toggle="modals" data-target="#modals-delete" -->
      <a class="button" style="cursor: pointer" @click="proceedToPayment"
        >Proceed to payment</a
      >
    </div>
  </div>

  <!-- Steps -->
  <div class="plans_step">
    <div class="step">
      <span class="span-img span-img-green"
        ><img src="@/assets/images/file-text.svg" alt="step"
      /></span>
      <div class="step_text">
        <span class="step-text-header">STEP 1</span
        ><span class="step-text-desctiption">Company Details</span>
      </div>
    </div>
    <div class="step-line-choosen"></div>
    <div class="step">
      <span class="span-img span-img-green"
        ><img src="@/assets/images/award.svg" alt="step"
      /></span>
      <div class="step_text">
        <span class="step-text-header">STEP 2</span
        ><span class="step-text-desctiption">Subscription</span>
      </div>
    </div>
    <div class="step-line-choosen"></div>
    <div class="step">
      <span class="span-img span-img-green"
        ><img src="@/assets/images/clipboard.svg" alt="step"
      /></span>
      <div class="step_text">
        <span class="step-text-header">STEP 3</span
        ><span class="step-text-desctiption">Hiring requirements</span>
      </div>
    </div>
    <div class="step-line-choosen"></div>
    <div class="step">
      <span class="span-img span-img-purple"
        ><img src="@/assets/images/credit-card.svg" alt="step"
      /></span>
      <div class="step_text">
        <span class="step-text-header">STEP 4</span
        ><span class="step-text-desctiption">Payment</span>
      </div>
    </div>
  </div>

  <!-- Modals card -->
  <div class="modals modal_plan" id="modals-plan">
    <div class="modal_top">
      <p>What is Sponsored Advertising?</p>
      <a href="#" class="close btn-close modal-x" data-close="modals">⨉</a>
    </div>
    <div class="modal_plan_middle">
      <ChoosePlanCardComponet
        v-for="subscription in subscriptionPlans"
        :key="subscription.id"
        :id="subscription.id"
        :name="subscription.name"
        :isUnlimitedLeads="subscription.isUnlimitedLeads"
        :isSponsoredAdvertisement="subscription.isSponsoredAdvertisement"
        :defaultLeadsCount="subscription.defaultLeadsCount"
        :costPerLead="subscription.costPerLead"
        :defaultPrice="subscription.defaultPrice"
        :logoBase64="subscription.logoBase64"
      />
    </div>
  </div>

  <!-- Modal advertising help -->
  <div class="modals modal_adver" id="modals-adver">
    <div class="modal_top">
      <p>What is Sponsored Advertising?</p>
      <a href="#" class="close btn-close modal-x" data-close="modals">⨉</a>
    </div>
    <div class="modal_middle">
      <span
        >Your ad will be shown on our main site to all site users as in the
        photo</span
      >
      <img src="@/assets/images/hiring-img.png" alt="img" />
    </div>
  </div>
  <div class="overlay hidden"></div>

  <!-- Modal -->
  <div class="modals modal-confirm" id="modals-delete">
    <h3>
      If you have active subscriptions, they will be canceled. Do you agree?
    </h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">No</button>
      <button
        class="button btn-exit"
        data-close="modals"
        @click="createCheckoutSession"
      >
        Yes
      </button>
    </div>
  </div>
</template>

<script>
 import useVuelidate from "@vuelidate/core";
import subscriptionService from "@/services/subscriptionService";
import ChoosePlanCardComponet from "./companyChoosePlanCardComponent/choosePlanCardComponet.vue";
import { required, minValue, maxValue} from "@vuelidate/validators";
export default {
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    this.getSubscription();
    this.getSubscriptionPlans();
    document.addEventListener("click", function (e) {
      const overlay_modals = document.querySelector(".overlay");
      let $target = e.target;
      if ($target.closest('[data-toggle="modals"]')) {
        e.preventDefault();
        $target = $target.closest('[data-toggle="modals"]');
        document.querySelector($target.dataset.target).classList.add("open");
        overlay_modals.setAttribute(
          "data-closeoverlay",
          $target.dataset.target
        );
        overlay_modals.classList.remove("hidden");
      } else if ($target.dataset.close === "modals") {
        e.preventDefault();
        $target.closest(".modals").classList.remove("open");
        overlay_modals.classList.add("hidden");
      } else if ($target.dataset.closeoverlay != "null") {
        e.preventDefault();
        //document.querySelector($target.dataset.closeoverlay).classList.remove("open");
        overlay_modals.classList.add("hidden");
        $target.dataset.closeoverlay = null;
      }
    });
  },
  components: {
    ChoosePlanCardComponet,
  },
   
  methods: {
   
    image(test) {
      var self = this;
      switch(test) {
        
  case "Basic":
    self.correctImage=require(`@/assets/images/basic-medal.svg`);
    break;
  case "Standard":
  self.correctImage=require(`@/assets/images/silver.svg`);
    break;
  case "Premium":
  self.correctImage=require(`@/assets/images/premium.svg`);
    break;
  default:}
      return self.correctImage; 
    },
    getSubscription: async function () {
      var response = await subscriptionService.getSubscriptionPlan();

      if (response.data.successful) {
        this.subscription = response.data.item;
        this.createCheckoutSessionRequest.leadsCount =
          this.subscription.defaultLeadsCount;
      } else {
        alert(response.data.errors[0].messages[0]);
      }
    },
    addJobDetail: function () {
      if(this.createCheckoutSessionRequest.jobDetails.length < 6){
        this.createCheckoutSessionRequest.jobDetails.push("");
      }
      
    },
    removeJobDetail: function () {
      this.createCheckoutSessionRequest.jobDetails.pop();
    },
    createCheckoutSession: async function () {
      var self = this;
      // debugger
      // this.v$.$validate();
      // if (this.v$.$error) {
      //   return;
      // }
      // debugger;
      var response = await subscriptionService.createCheckoutSession(
        self.createCheckoutSessionRequest
      );
      if (response.data.successful) {
        window.open(response.data.item, "_blank");
      } else {
        alert(response.data.errors[0].messages[0]);
      }
    },
    getSubscriptionPlans: async function () {
      var response = await subscriptionService.getSubscriptionPlans();

      if (response.data.successful) {
        response.data.items.forEach(element => {
          switch(element.name) {
  case "Basic":
    element.logoBase64=require(`@/assets/images/basic-medal.svg`);
    // this.correctImage=require(`@/assets/images/premium.svg`);
    break;
  case "Standard":
  element.logoBase64=require(`@/assets/images/silver.svg`);
  // this.correctImage=require(`@/assets/images/premium.svg`);
    break;
  case "Premium":
  element.logoBase64=require(`@/assets/images/premium.svg`);
  // this.correctImage=require(`@/assets/images/premium.svg`);
    break;
  default:}
        });
        this.subscriptionPlans = response.data.items;
        this.image(this.subscription.name);
      } else {
        alert(response.data.errors[0].messages[0]);
      }
    },
    proceedToPayment: async function () {
      var self = this;
      debugger
      self.createCheckoutSession();
      // var response = await subscriptionService.getActiveSubscriptionPlan();
      // if (response.data.successful && response.data.item) {
      //   self.openModal("modals-delete");
      // } else {
      //   self.createCheckoutSession();
      // }
    },
    openModal: function (modalId) {
      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById(modalId);
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
    },
    closeModal: function (modalId) {
      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById(modalId);
      toast_modal.classList.remove("open");
      overlay_modals.classList.add("hidden");
    },
  },
  watch: {
    "createCheckoutSessionRequest.leadsCount": function () {
      if (this.createCheckoutSessionRequest.leadsCount > 50) {
        this.createCheckoutSessionRequest.leadsCount = 50;
      }
      //  else if (this.createCheckoutSessionRequest.leadsCount < 20) {
      //   alert("The smallest value that can be entered here is 20")
      // }
     
    },
  },
  data() {
    return {
      // Request models
      createCheckoutSessionRequest: {
        leadsCount: 0,
        subject: "",
        jobDetails: [],
      },
      // Response models
      subscriptionPlans: [],
      subscription: {
        id: null,
        name: null,
        isUnlimitedLeads: null,
        isSponsoredAdvertisement: null,
        defaultLeadsCount: null,
        costPerLead: null,
        defaultPrice: null,
        logoBase64: null,
      },
      correctImage:null
    };
  },
  validations() {
      return {
        createCheckoutSessionRequest: {
          leadsCount: {
            required,
            maxValue: maxValue(50),
            minValue: minValue(20)
          }
        }
      };
    }
};
</script>
