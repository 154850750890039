<template>
    <div class="plan_card1" :class="{ premium_class: isSponsoredAdvertisement }">
      <div class="plan_top1">
        <div class="plan_name1">
          <span>{{ name1 }}</span>
          <span class="plan_name21">{{ name2 }}</span>
  
          <span class="plan_logo1">
            <img
              :src="require(`@/assets/images/` + image)"
              alt=""
              v-if="image !== null"
            />
          </span>
        </div>
        <div class="plan_name31">
          <span>{{ name3 }}</span>
        </div>
  
        <div class="plan_chances">
          <span class="chance" v-for="o in words" :key="o.id">
            <img src="@/assets/images/tick-icon.svg" alt="" />
            <p>{{ o.name }}</p>
          </span>
        </div>
      </div>
      <div class="plan_bottom1">
        <router-link to="/carrier-register" class="button but_bordered"
          >Request Pricing</router-link
        >
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {},
    props: {
      id: null,
      image: null,
      name1: null,
      name2: null,
      name3: null,
      words: [],
    },
  };
  </script>
  
  <style></style>