<template>
    <div class="row_right">
        <DashboardTitleComponent title="Hiring requirements" />

        <section class="driver_add_main">
            <div class="form_block form_chek_radio">

                <div class="form_top">
                    <h3>Hiring requirements</h3>
                    <a 
                        v-if="!editable" 
                        @click="changeEditable" 
                        class="button" 
                        style="cursor: pointer"
                    >
                        Edit requirements
                    </a>
                </div>

                <div class="row_form bot_divider">
                    <div class="col_form state_form">
                        <div class="col_title">
                            State
                            <span v-if="editable">*</span>
                        </div>
                        <Multiselect 
                            mode="single" 
                            valueProp="id" 
                            group-values="name" 
                            track-by="name" 
                            label="name"
                            :options="states" 
                            v-model="createOrEditVacancyModel.stateId" 
                            :searchable="true"
                            :caret="true" 
                            :hide-selected="false" 
                            :close-on-select="true" 
                            noOptionsText="City not selected"
                            noResultsText="No state found!" 
                            placeholder="Washington, Seattle...."
                            :disabled="!editable"
                        />
                    </div>
                </div>

                <div class="row_form bot_divider">
                    <!-- Driver types -->
                    <div class="col_form">
                        <div class="col_title">
                            Driver Type <span v-if="editable">*</span>
                        </div>
                        <div v-for="driverType in driverTypes" :key="driverType.id" class="form-check">
                            <input class="form-styled" type="checkbox" :value="driverType.id"
                                :id="`driverType_${driverType.id}`" v-model="createOrEditVacancyModel.driverTypeIds"
                                :disabled="!editable && createOrEditVacancyModel.driverTypeIds.some(x => x === driverType.id)">
                            <label :for="`driverType_${driverType.id}`">
                                {{ driverType.type }}
                            </label>
                        </div>
                    </div>
                    <!-- Experience -->
                    <div class="col_form">
                        <div class="col_title">
                            Experience <span v-if="editable">*</span>
                        </div>

                        <div v-for="exp in experiences" :key="exp.id" class="form-check">
                            <input class="form-styled radio_styled" type="radio" name="radioGroup_1" :id="`Radio_${exp.id}`"
                                :value="exp.id" v-model="createOrEditVacancyModel.experienceId"
                                :disabled="!editable && createOrEditVacancyModel.experienceId === exp.id">
                            <label :for="`Radio_${exp.id}`">
                                {{ exp.value }}
                            </label>
                        </div>

                    </div>
                    <!-- Route types -->
                    <div class="col_form">
                        <div class="col_title">
                            Route Type <span v-if="editable">*</span>
                        </div>
                        <div v-for="routeType in routeTypes" :key="routeType.id" class="form-check">
                            <input class="form-styled" type="checkbox" :value="routeType.id"
                                :id="`routeType_${routeType.id}`" v-model="createOrEditVacancyModel.routeTypeIds"
                                :disabled="!editable && createOrEditVacancyModel.routeTypeIds.some(x => x === routeType.id)">
                            <label :for="`routeType_${routeType.id}`">
                                {{ routeType.type }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row_form">
                    <!-- Freight types -->
                    <div class="col_form">
                        <div class="col_title">
                            Freight Type <span v-if="editable">*</span>
                        </div>
                        <div v-for="freightType in freightTypes" :key="freightType.id" class="form-check">
                            <input class="form-styled" type="checkbox" :value="freightType.id"
                                :id="`freightType_${freightType.id}`" v-model="createOrEditVacancyModel.freightTypeIds"
                                :disabled="!editable && createOrEditVacancyModel.freightTypeIds.some(x => x === freightType.id)">
                            <label :for="`freightType_${freightType.id}`">
                                {{ freightType.type }}
                            </label>
                        </div>
                    </div>
                    <!-- Endorsements -->
                    <div class="col_form">
                        <div class="col_title">
                            Endorsements
                        </div>
                        <div v-for="end in endorsements" :key="end.id" class="form-check">
                            <input class="form-styled" type="checkbox" :value="end.id" :id="`endorsement_${end.id}`"
                                v-model="createOrEditVacancyModel.endorsementIds"
                                :disabled="!editable && createOrEditVacancyModel.endorsementIds.some(x => x === end.id)">
                            <label :for="`endorsement_${end.id}`">
                                {{ end.name }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="requirement_bottom">
                    <a href="#" class="button " @click="this.$router.push({ path: '/dashboard' })">Cancel</a>
                    <button v-if="!this.v$.$invalid && editable" class="button"
                        style="border: none; font-family: 'Poppins', sans-serif; cursor: pointer;" @click="createOrEdit">
                        Submit
                    </button>
                </div>
            </div>
        </section>
    </div>
    <div class="overlay hidden"></div>

    <!-- Modal notification -->
    <div class="modals modal_notification" id="modal">
        <div class="content_group">
            <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
            <div class="modal_content">
                <span class="not_title">{{ modal.title }}</span>
                <span class="modal_text">{{ modal.body }}</span>
            </div>
        </div>
        <a href="#" class="close btn-close" data-close="modals">⨉</a>
    </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import driverTypeService from '@/services/driverTypeService';
import experienceService from '@/services/experienceService';
import routeTypeService from '@/services/routeTypeService';
import freightTypeService from '@/services/freightTypeService';
import endorsementService from '@/services/endorsementService';
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import vacancyService from '@/services/vacancyService';
import StateService from '@/services/stateService';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";

export default {
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
        this.getDriverTypes();
        this.getExperiences();
        this.getRouteTypes();
        this.getFreightTypes();
        this.getEndorsements();
        this.getStates();
        this.loadHiringRequirements();
    },
    components: {
        DashboardTitleComponent,
        Multiselect
    },
    methods: {
        getDriverTypes: async function () {
            var response = await driverTypeService.getDriverTypes();
            if (response.data.successful) {
                this.driverTypes = response.data.items;
            }
        },
        getExperiences: async function () {
            var response = await experienceService.getExperiences();
            if (response.data.successful) {
                this.experiences = response.data.items;
            }
        },
        getRouteTypes: async function () {
            var response = await routeTypeService.getRouteTypes();
            if (response.data.successful) {
                this.routeTypes = response.data.items;
            }
        },
        getFreightTypes: async function () {
            var response = await freightTypeService.getFreightTypes();
            if (response.data.successful) {
                this.freightTypes = response.data.items;
            }
        },
        getEndorsements: async function () {
            var response = await endorsementService.getEndorsements();
            if (response.data.successful) {
                this.endorsements = response.data.items;
            }
        },
        getStates: async function () {
            var response = await StateService.getStates();
            if (response.data.successful) {
                this.states = response.data.items;
            }
        },
        loadHiringRequirements: async function () {
            var response = await vacancyService.getVacancyRequirements();
            if (response.data.successful && response.data.item) {
                var requirements = response.data.item;

                this.createOrEditVacancyModel.vacancyId = requirements.vacancyId;
                this.createOrEditVacancyModel.stateId = requirements.state.id;
                this.createOrEditVacancyModel.experienceId = requirements.experience.id;

                this.createOrEditVacancyModel.driverTypeIds = [];
                requirements.driverTypes.forEach(x => this.createOrEditVacancyModel.driverTypeIds.push(x.id));

                this.createOrEditVacancyModel.freightTypeIds = [];
                requirements.freightTypes.forEach(x => this.createOrEditVacancyModel.freightTypeIds.push(x.id));

                this.createOrEditVacancyModel.routeTypeIds = [];
                requirements.routeTypes.forEach(x => this.createOrEditVacancyModel.routeTypeIds.push(x.id));

                this.createOrEditVacancyModel.endorsementIds = [];
                requirements.endorsements.forEach(x => this.createOrEditVacancyModel.endorsementIds.push(x.id));
            }
        },
        createOrEdit: async function () {
            var self = this;
            var response = {};
            if (self.createOrEditVacancyModel.vacancyId) {
                response = await vacancyService.editVacancy(self.createOrEditVacancyModel);
                if (response.data.successful) {
                    self.loadHiringRequirements();
                    self.toggleModal(self.modal.successIcon, "Success", "Hiring requirements have been edited");
                } else {
                    self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
                }
            } else {
                response = await vacancyService.createVacancy(self.createOrEditVacancyModel);
                if (response.data.successful) {
                    self.loadHiringRequirements();
                    self.toggleModal(self.modal.successIcon, "Success", "Hiring requirements have been created");
                } else {
                    self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
                }
            }
        },
        changeEditable: function () {
            var self = this;
            self.loadHiringRequirements();
            self.editable = true;
        },
        toggleModal: function (imgSrc, title, body) {
            var self = this;
            self.modal.selectedIcon = imgSrc;
            self.modal.title = title;
            self.modal.body = body;

            var overlay_modals = document.querySelector(".overlay");
            const toast_modal = document.getElementById("modal");
            toast_modal.classList.add('open');
            overlay_modals.classList.remove("hidden");
            setTimeout(function () {
                toast_modal.classList.remove('open');
                overlay_modals.classList.add("hidden");
            }, 3000);
        }
    },
    data() {
        return {
            createOrEditVacancyModel: {
                vacancyId: null,
                experienceId: null,
                driverTypeIds: [],
                freightTypeIds: [],
                routeTypeIds: [],
                endorsementIds: [],
                stateId: null
            },
            driverTypes: [],
            experiences: [],
            routeTypes: [],
            freightTypes: [],
            endorsements: [],
            states: [],
            editable: this.isEditable,
            modal: {
                title: "",
                body: "",
                successIcon: "success_icon.svg",
                warningIcon: "warning_icon.svg",
                errorIcon: "error_icon.svg",
                selectedIcon: "success_icon.svg"
            }
        }
    },
    props: ["isEditable"],
    validations() {
        return {
            createOrEditVacancyModel: {
                experienceId: {
                    required: helpers.withMessage("Experience is required!", required)
                },
                freightTypeIds: {
                    required: helpers.withMessage("Freight types are required!", required),
                    minLength: minLength(1)
                },
                routeTypeIds: {
                    required: helpers.withMessage("Route types are required!", required),
                    minLength: minLength(1)
                },
                driverTypeIds: {
                    required: helpers.withMessage("Driver types are required!", required),
                    minLength: minLength(1)
                }
            }
        }
    }
}
</script>
