<template>
  <div class="row_right">
    <DashboardTitleComponent title="Users" />
    <div class="users_bar">
      <div class="search">
        <input type="text" placeholder="Search by name" class="search-field" />
        <button type="button" class="search-button">
          <img src="@/assets/images/search.svg">
        </button>
      </div>
      <router-link class="btn-new-admin" to="/create-user">New user</router-link>
    </div>
    <div class="users_table">
      <div class="users_table_header">
        <div class="col-1">Full name</div>
        <div class="col-1">Email</div>
        <div class="col-3"></div>
      </div>
      <UserTableComponent v-for="user in users" :key="user.id" :userName=user.userName :email=user.email />
    </div>
  </div>
  <div class="modals modals-delete" id="modals-delete">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit">Yes</button>
    </div>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import UserTableComponent from './inComponents/userTableComponent.vue';

export default {
  data() {
    return {
      users: [
        {
          id: 1,
          userName: 'Ernest Vinson',
          email: 'ernest_vinson@gmail.com'
        },
        {
          id: 2,
          userName: 'Winnie Mcfarland',
          email: 'winnie_mcfarland@gmail.com'
        }
      ]
    }
  },
  components: { DashboardTitleComponent, UserTableComponent },
  methods: {

  },
  mounted() {

  },

}
</script>

<style  >

</style>