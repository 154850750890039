<template>
  <div class="form_block">
    <div class="form_row_left">
      <img :src="`data:image/png;base64,${companyDetails.logoBase64Encoded}`" alt="upload">
      <p>Company Logo</p>
      <label for="file_upload" class="button">Browse</label>
      <input type="file" id="file_upload" name="file_upload" style="display: none;">
    </div>
    <div class="form_row_right">

      <div class="col_form top_company">
        <label for="company">Company name <span>*</span></label>
        <input type="text" id="companyName" name="company" v-model="companyDetails.companyName">
      </div>

      <div class="col_form bot_company">
        <h3>Contact Info</h3>
        <div class="bot_row_form">
          <div class="form_input">
            <label for="">First name <span>*</span></label>
            <input type="text" id="firstName" class="form-control" v-model="userDetails.firstName">
          </div>
          <div class="form_input">
            <label for="">Last name <span>*</span></label>
            <input type="text" id="lastName" class="form-control" v-model="userDetails.lastName">
          </div>
        </div>
        <div class="bot_row_form">
          <div class="form_input">
            <label for="">Email <span>*</span></label>
            <input type="text" id="email" class="form-control" v-model="companyDetails.email">
          </div>
          <div class="form_input">
            <label for="">Phone number <span>*</span></label>
            <input v-maska="['+1 (###) ###-####', '+1 (###) ###-####']" type="text" class="form-control"
              v-model="companyDetails.phoneNumber" id="phoneNumber">
          </div>
        </div>
      </div>

      <!-- <div class="col_form bot_company"> may need future
        <h3>Contact Info</h3>
        <CompanyFormComponents v-for="number in numberContacts" :key="number" />
        <a href="#" class="add-new" @click="addContact"> <img src="@/assets/images/plus-circle.svg" alt="plus"><span>Addnew</span></a>
      </div> -->


      <div class="btn_container">
        <router-link class="button" @click="editCompanyDetails" to="/choose-plan">Submit</router-link>
      </div>

    </div>

  </div>

  <div class="plans_step">
    <div class="step">
      <span class="span-img span-img-purple "><img src="@/assets/images/file-text.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 1</span><span class="step-text-desctiption">Company Details</span>
      </div>
    </div>
    <div class="step-line">
    </div>
    <div class="step">
      <span class="span-img span-img-grey "><img src="@/assets/images/award.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 2</span><span class="step-text-desctiption">Subscription</span>
      </div>
    </div>
    <div class="step-line">
    </div>
    <div class="step">
      <span class="span-img span-img-grey"><img src="@/assets/images/clipboard.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 3</span><span class="step-text-desctiption">Hiring requirements</span>
      </div>
    </div>
    <div class="step-line">
    </div>
    <div class="step">
      <span class="span-img span-img-grey "><img src="@/assets/images/credit-card.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 4</span><span class="step-text-desctiption">Payment</span>
      </div>
    </div>
  </div>
</template>

<script>
// import CompanyFormComponents from './companyDetailsComponents/companyFormComponents.vue'; may need future

// services
import companyService from '@/services/companyService';
import userService from '@/services/userService';

export default {
  mounted() {

    this.getCompanyDetails();
    this.getCurrentUserProfileData();
  },
  methods: {
    getCompanyDetails: async function () {
      var self = this;
      var response = await companyService.getCompanyDetailsWithoutCompanyId();

      if (response.data.successful) {
        self.companyDetails = response.data.item;
      }
    },
    getCurrentUserProfileData: async function () {
      var self = this;
      var response = await userService.getCurrentUserProfileData();
      if (response.data.successful) {
        self.userDetails = response.data.item;
      }

    },
    editCompanyDetails: async function () {
      const formData = new FormData();
      formData.append("companyName", document.getElementById("companyName").value);
      formData.append("firstName", document.getElementById("firstName").value);
      formData.append("lastName", document.getElementById("lastName").value);
      formData.append("email", document.getElementById("email").value);
      formData.append("phoneNumber", document.getElementById("phoneNumber").value);
      var profilePicture = document.getElementById("file_upload").files[0];

      if (profilePicture) {
        formData.append("Logo", profilePicture);
      }
      await companyService.editCompanyDetails(formData);
    },
    addContact() {
      this.numberContacts += 1;
    },
  },
  data() {
    return {
      //Request model
      companyRequestDto: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        logo: '',
      },
      // Response models
      companyDetails: {
        companyId: 0,
        companyName: '',
        contacteeName: '',
        email: '',
        logoBase64Encoded: '',
        subscriptionPlanLogoBase64Encoded: '',
        phoneNumber: '',
        subscription: ''
      },
      userDetails: {
        firstName: '',
        lastName: ''
      },
      numberContacts: 1,
    }
  },
  // components: { CompanyFormComponents }
}
</script>

<style ></style>