import authService from "@/services/authService";
import errorHandler from "@/helpers/errorHandler";

export default {
    getSubscriptionPlans: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get('/api/subscription/getSubscriptionPlans')
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
    choosePlan: async function (planId) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post(`/api/subscription/choosePlan?planId=${planId}`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
    getSubscriptionPlan: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get(`/api/subscription/getSubscriptionPlan`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
    createCheckoutSession: async function (createCheckoutSessionRequest) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post(`/api/subscription/createCheckoutSession`, createCheckoutSessionRequest)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
    getActiveSubscriptionPlan: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get(`/api/subscription/getActiveSubscriptionPlan`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
}