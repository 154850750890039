<template>
  <div class="users_table_row">
    <div class="col-1">{{ userName }}</div>
    <div class="col-1">{{ email }}</div>
    <div class="col-3">
      <router-link to="/create-user">
        <img src="@/assets/images/edit.svg" alt="edit">
      </router-link>
      <a a href="#" data-toggle="modals" data-target="#modals-delete"><img src="@/assets/images/trash.svg"
          alt="delete"></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userName', 'email']
}
</script>

<style lang="scss" scoped>

</style>