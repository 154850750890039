<template>
  <section class="dashboard">
    <div class="container">
      <div class="row">
        <SidebarComponent />
        <CreateUserComponent />
      </div>
    </div>
  </section>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import SidebarComponent from '@/components/shared/sidebarComponent.vue';
import CreateUserComponent from '@/components/usersContentComponents/createUserComponent.vue';





export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: { SidebarComponent, CreateUserComponent, Loading }
}
</script>

<style lang="scss" scoped>

</style>