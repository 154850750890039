<template>
  <div class="row_right">
    <DashboardTitleComponent title="Create user" />
    <router-link class="btn-to-users" to="/users">
      <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
    </router-link>
    <div class="user_add_main">
      <div class="form_block">
        <div class="row_form">
          <div class="col_form">
            <label for="user" class="col_title">Firstname</label>
            <input type="text" class="form-control" placeholder="Ernest">
          </div>
          <div class="col_form">
            <label for="user" class="col_title">Lastname</label>
            <input type="text" class="form-control" placeholder="Vinson">
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <label for="user" class="col_title">Email</label>
            <input type="email" class="form-control" placeholder="ernest_vinson@gmail.com">
          </div>
          <div class="col_form">
            <label for="user" class="col_title">Password</label>
            <input type="password" class="form-control" placeholder="••••••••••••••••">
          </div>
        </div>
      </div>
    </div>
    <div class="save_button_field">
      <a href="#" class="save-button">Save</a>
    </div>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

export default {
  components: { DashboardTitleComponent }
}
</script>

<style  scoped>

</style>