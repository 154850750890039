<template>
  <div class="row_right">
    <DashboardTitleComponent title="Dashboard" />
    <div class="top_notifications">
      <div class="company_notification">
        <p v-if="isMorning">Good morning</p>
        <p v-if="isAfternoon">Good afternoon</p>
        <p v-if="isEvening">Good evening</p>
        <p v-if="isNight">Welcome back</p>
        <h3 v-if="receviedLeadsToday < 1">No leads received yet!</h3>
        <h3 v-if="receviedLeadsToday === 1">
          {{ receviedLeadsToday }} lead received today
        </h3>
        <h3 v-if="receviedLeadsToday > 1">
          {{ receviedLeadsToday }} leads received today
        </h3>
      </div>
      <div class="company_notification">
        <div class="company_plan">
          <h3>Current plan</h3>
          <div class="plan">
            <!-- <img src="@/assets/images/premium.svg" alt="Premium"> -->
            <img :src="correctImage" alt="upload" />
            <span>{{ subscriptionPlan.name }}</span>
          </div>
        </div>
        <div class="company_plan_bottom">
          <div class="company_plan_items">
            <!-- Is unlimited leads -->
            <div class="item">
              <img
                v-if="subscriptionPlan.isUnlimitedLeads"
                src="@/assets/images/check.png"
                alt="check"
              />
              <img :src="require(`@/assets/images/x.svg`)" alt="" v-else />
              <span>Unlimited leads</span>
            </div>

            <!-- Is sponsored advertisement -->
            <div class="item">
              <img
                v-if="subscriptionPlan.isSponsoredAdvertisement"
                src="@/assets/images/check.png"
                alt="check"
              />
              <img :src="require(`@/assets/images/x.svg`)" alt="" v-else />
              <span>Sponsored Advertisement</span>
            </div>
          </div>
          <div class="company_button_content">
            <a href="https://findcdl.com/pricing" target="_blank" class="company_plan_button"
              >Change plan</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="latest_leads">
      <p>Latest Leads</p>
      <div>
        <ul class="responsive-table drivers-table">
          <li class="table-header">
            <div class="col col-1">#</div>
            <div class="col col-2">Full name</div>
            <div class="col col-3">Phone number</div>
            <div class="col col-4">Driver type</div>
            <div class="col col-5">Created date</div>
            <div class="col col-6">&nbsp;</div>
          </li>
          <DriverListComponent
            v-for="(driver, index) in drivers" :key="driver.driverId" :id="driver.driverId" :driverorder="index + 1"
            :fullName="driver.firstName + ' ' + driver.lastName"
            :phoneNumber="driver.phoneNumber"
            :driverTypes="driver.driverTypes"
            :createdDate="driver.createdDate"
            :isDeleted="driver.isDeleted"
          />
        </ul>
      </div>
      <div class="all_leads_button_content">
        <a href="/leads" class="button">See all leads</a>
      </div>
    </div>
  </div>
</template>

<script>
  import DriverListComponent from "../driversComponents/inComponent/driverListComponent.vue";
  import DashboardTitleComponent from "../shared/dashboardTitleComponent.vue";

  // services
  import driverService from "@/services/driverService";
  import subscriptionService from "@/services/subscriptionService";
  import companyService from "@/services/companyService";
  import authService from "@/services/authService";

  export default {
    async mounted() {
      var checkStatus = await authService.getCompanyStatus();
      switch (checkStatus.data) {
        case 0:
          this.$router.push("/login");
          return;
        case 1:
          this.$router.push("/choose-plan");
          return;
        case 2:
          this.$router.push("/requirement");
          return;
        case 3:
          this.$router.push("/payment");
          return;
        case 4:
          this.setState();
          break;
        default:
          this.$router.push("/login");
          return;
      }

      this.getActiveSubscriptionPlan();
      this.getDrivers();
      this.getReceviedLeadsToday();
      this.setLogo();
      this.setTime();
    },
    components: {
      DashboardTitleComponent,
      DriverListComponent,
    },

    methods: {
      setTime: function () {
        var self = this;
        // Get the current time
        var currentTime = new Date();

        // Get the hour component of the current time
        var currentHour = currentTime.getHours();

        // Define the intervals for different times of the day
        var morningStart = 3; // 6:00 AM
        var afternoonStart = 12; // 12:00 PM
        var eveningStart = 18; // 6:00 PM
        var nightStart = 9;

        if (currentHour >= morningStart && currentHour < afternoonStart) {
          self.isMorning = true;
        } else if (currentHour >= afternoonStart && currentHour < eveningStart) {
          self.isAfternoon = true;
        } else if (currentHour >= eveningStart && currentHour < nightStart) {
          self.isEvening = true;
        } else {
          self.isNight = true;
        }
      },
      setState: function () {
        this.$store.commit("setStatus", { status: true });
      },
      getReceviedLeadsToday: async function () {
        var self = this;
        var response = await driverService.getReceviedLeadsToday();

        if (response.data.successful) {
          self.receviedLeadsToday = response.data.item;
        }
      },
      getActiveSubscriptionPlan: async function () {
        var self = this;
        var response = await subscriptionService.getActiveSubscriptionPlan();

        if (response.data.successful) {
          switch (response.data.item.name) {
            case "Basic":
              self.correctImage = require(`@/assets/images/basic-medal.svg`);
              break;
            case "Standard":
              self.correctImage = require(`@/assets/images/silver.svg`);
              break;
            case "Premium":
              self.correctImage = require(`@/assets/images/premium.svg`);
              break;
            default:
            // code block
          }
          self.subscriptionPlan = response.data.item;
        }
      },
      getDrivers: async function () {
        var self = this;
        self.getDriversRequestModel.currentCompanyId =
          this.$store.getters.adminId;
        var response = await driverService.getDrivers(
          self.getDriversRequestModel,
          self.pagination.page,
          self.pagination.size
        );

        if (response.data.successful) {
          self.drivers = response.data.items;

          if (self.drivers.length > 0) {
            self.driversSuccessfullyLoaded = true;
            self.pagination.totalCount = response.data.totalCount;
          } else {
            self.driversSuccessfullyLoaded = false;
          }
        }
      },
      setLogo: async function () {
        var response = await companyService.getCompanyDetailsWithoutCompanyId();
        if (response.data.successful) {
          var logo = response.data.item.logoBase64Encoded;
          this.$store.commit("setlogo", { logo: logo });
        }
      },
    },
    data() {
      return {
        // Request models
        getDriversRequestModel: {
          name: "",
          experienceId: 0,
          currentCompanyId: 0,
          driverTypeIds: [],
          freightTypeIds: [],
          routeTypeIds: [],
          endorsementIds: [],
        },
        // Response models
        driversSuccessfullyLoaded: false,
        drivers: [],
        driverTypes: [],
        receviedLeadsToday: 0,
        subscriptionPlan: {
          name: "",
          isUnlimitedLeads: false,
          isSponsoredAdvertisement: false,
          costPerLead: 0.0,
          defaultLeadsCount: 0,
          defaultPrice: 0.0,
        },
        pagination: {
          page: 1,
          totalCount: 0,
          size: 10,
        },
        isMorning: false,
        isAfternoon: false,
        isEvening: false,
        isNight: false,
        correctImage: null,
      };
    },
  };
</script>

<style></style>
