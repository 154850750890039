<template>
  <div class="top_row">
    <h1 class="top_title">{{ title }}</h1>
    <div class="sidebar_right">
      <router-link to="/profile" href class="user_bar" data-toggle="modals" data-target="#modals-2" v-if="isAuthenticated()">
        <span class="top_user">
          <img :src="`data:image/png;base64,${ this.$store.getters.logoBase64Encoded }`" />
        </span>
        <div class="user_data">
          <span class="user_name">{{ this.$store.getters.companyEmail }}</span>
          <span class="user_role">Company Profile</span>
        </div>
      </router-link>
    </div>
  </div>
  <!-- <div class="modals modal_user" id="modals-2">
    <a href="#">
      <img src="@/assets/images/user.svg" alt="">
      <router-link to="/profile">Profile</router-link>
    </a>
    <router-link to="/users">
      <img src="@/assets/images/tool.svg" alt="">
      <span>Users</span>
    </router-link>
  </div> -->
</template>

<script>
export default {
  methods: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated && this.$store.getters.isSubscriptionActive;
    }
  },
  props: ['title'],
}
</script>

<style lang="scss" scoped>

</style>