<template>
    <div class="row_right">
        <DashboardTitleComponent title="Hiring requirements" />

        <section class="driver_add_main">
            <div class="form_block form_chek_radio">

                <div class="form_top">
                    <h3>Hiring requirements</h3>
                </div>
                
                <div class="row_form bot_divider">
                    <div class="col_form">
                        <div class="col_title">
                            Driver type <span>*</span>
                        </div>
                        <div v-for="driverType in driverTypes" :key="driverType.id" class="form-check">
                            <input class="form-styled checkbox_styled" type="checkbox" :value="driverType.id"
                                :id="`driverType_${driverType.id}`" v-model="editVacancyRequest.driverTypeIds">
                            <label :for="`driverType_${driverType.id}`">
                                {{ driverType.type }}
                            </label>
                        </div>

                    </div>
                    <div class="col_form">
                        <div class="col_title">
                            Experience <span>*</span>
                        </div>

                        <div v-for="exp in experiences" :key="exp.id" class="form-check">
                            <input class="form-styled radio_styled" type="radio" name="radioGroup_1"
                                :id="`Radio_${exp.id}`" :value="exp.id" v-model="editVacancyRequest.experienceId">
                            <label :for="`Radio_${exp.id}`">
                                {{ exp.value }}
                            </label>
                        </div>

                    </div>
                    <div class="col_form">
                        <div class="col_title">
                            Route Type <span>*</span>
                        </div>
                        <div v-for="routeType in routeTypes" :key="routeType.id" class="form-check">
                            <input class="form-styled checkbox_styled" type="checkbox" :value="routeType.id"
                                :id="`routeType_${routeType.id}`" v-model="editVacancyRequest.routeTypeIds">
                            <label :for="`routeType_${routeType.id}`">
                                {{ routeType.type }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row_form">
                    <div class="col_form">
                        <div class="col_title">
                            Freight Type <span>*</span>
                        </div>
                        <div v-for="freightType in freightTypes" :key="freightType.id" class="form-check">
                            <input class="form-styled checkbox_styled" type="checkbox" :value="freightType.id"
                                :id="`freightType_${freightType.id}`" v-model="editVacancyRequest.freightTypeIds">
                            <label :for="`freightType_${freightType.id}`">
                                {{ freightType.type }}
                            </label>
                        </div>
                    </div>
                    <div class="col_form">
                        <div class="col_title">
                            Endorsements <span>*</span>
                        </div>
                        <div v-for="end in endorsements" :key="end.id" class="form-check">
                            <input class="form-styled checkbox_styled" type="checkbox" :value="end.id"
                                :id="`endorsement_${end.id}`" v-model="editVacancyRequest.endorsementIds">
                            <label :for="`endorsement_${end.id}`">
                                {{ end.name }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="requirement_bottom">
                    <a href="#" class="button " @click="this.$router.push({ path: '/hiring-requirments' })">Cancel</a>
                    <button v-if="!this.v$.$invalid" class="button"
                        style="border: none; font-family: 'Poppins', sans-serif; cursor: pointer;" @click="editVacancy">
                        Submit
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

// services
import driverTypeService from '@/services/driverTypeService';
import experienceService from '@/services/experienceService';
import routeTypeService from '@/services/routeTypeService';
import freightTypeService from '@/services/freightTypeService';
import endorsementService from '@/services/endorsementService';
import "@vueform/multiselect/themes/default.css";
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import vacancyService from '@/services/vacancyService';

export default {
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
        this.getDriverTypes();
        this.getExperiences();
        this.getRouteTypes();
        this.getFreightTypes();
        this.getEndorsements();
        this.getVacancyRequirements();
    },
    components: { DashboardTitleComponent },
    methods: {
        getDriverTypes: async function () {
            var response = await driverTypeService.getDriverTypes();
            if (response.data.successful) {
                this.driverTypes = response.data.items;
            }
        },
        getExperiences: async function () {
            var response = await experienceService.getExperiences();
            if (response.data.successful) {
                this.experiences = response.data.items;
            }
        },
        getRouteTypes: async function () {
            var response = await routeTypeService.getRouteTypes();
            if (response.data.successful) {
                this.routeTypes = response.data.items;
            }
        },
        getFreightTypes: async function () {
            var response = await freightTypeService.getFreightTypes();
            if (response.data.successful) {
                this.freightTypes = response.data.items;
            }
        },
        getEndorsements: async function () {
            var response = await endorsementService.getEndorsements();
            if (response.data.successful) {
                this.endorsements = response.data.items;
            }
        },
        getVacancyRequirements: async function () {
            var response = await vacancyService.getVacancyRequirements();
            if (response.data.successful) {
                var requirements = response.data.item;

                this.editVacancyRequest.vacancyId = requirements.vacancyId;
                this.editVacancyRequest.experienceId = requirements.experience.id;

                this.editVacancyRequest.driverTypeIds = [];
                requirements.driverTypes.forEach(x => this.editVacancyRequest.driverTypeIds.push(x.id));

                this.editVacancyRequest.freightTypeIds = [];
                requirements.freightTypes.forEach(x => this.editVacancyRequest.freightTypeIds.push(x.id));

                this.editVacancyRequest.routeTypeIds = [];
                requirements.routeTypes.forEach(x => this.editVacancyRequest.routeTypeIds.push(x.id));

                this.editVacancyRequest.endorsementIds = [];
                requirements.endorsements.forEach(x => this.editVacancyRequest.endorsementIds.push(x.id));
            } else {
                alert("Could not load a vacancy!");
            }
        },
        editVacancy: async function () {
            var self = this;
            var response = await vacancyService.editVacancy(self.editVacancyRequest);
            if (response.data.successful) {
                this.getVacancyRequirements();
                alert("Requirements have been edited!");
            } else {
                alert(response.data.errors[0].messages[0]);
            }
        }
    },
    data() {
        return {
            // Request models
            editVacancyRequest: {
                vacancyId: null,
                experienceId: null,
                driverTypeIds: [],
                freightTypeIds: [],
                routeTypeIds: [],
                endorsementIds: []
            },
            // Response models
            driverTypes: [],
            experiences: [],
            routeTypes: [],
            freightTypes: [],
            endorsements: []
        }
    },
    validations() {
        return {
            editVacancyRequest: {
                experienceId: {
                    required: helpers.withMessage("Experience is required!", required)
                },
                freightTypeIds: {
                    required: helpers.withMessage("Freight types are required!", required),
                    minLength: minLength(1)
                },
                routeTypeIds: {
                    required: helpers.withMessage("Route types are required!", required),
                    minLength: minLength(1)
                },
                driverTypeIds: {
                    required: helpers.withMessage("Driver types are required!", required),
                    minLength: minLength(1)
                },
                endorsementIds: {
                    required: helpers.withMessage("Endorsements are required!", required),
                    minLength: minLength(1)
                }
            }
        }
    }
}
</script>
<style>
.form_chek_radio .form_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form_top .button {
    height: 44px;
    padding: 10px 20px;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: #26A28F;
    color: #FFFFFF;
}

.requirement_bottom {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.requirement_bottom .button:nth-child(1) {
    background: #F0F0F0;
    border-radius: 10px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

}

.requirement_bottom .button:nth-child(2) {
    background: #26A28F;
    border-radius: 10px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

}
</style>