<template>
  <div class="row_right">
    <DashboardTitleComponent :title="driverDetails.firstName + ' ' + driverDetails.lastName" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/leads">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
      </router-link>
      <div class="info_right">
        <!-- <router-link class="btn-info-edit" to="/add-driver"><img src="@/assets/images/edit.svg"
            alt="edit"><span>Edit</span></router-link>
        <a data-toggle="modals" data-target="#modals-delete" class="btn-info-delete" href="#"><img
            src="@/assets/images/trash.svg" alt="trash"><span>Remove</span></a> -->
      </div>
    </div>
    <div class="driver_info">
      <h3>Personal info</h3>
      <div class="driver_contact">
        <div class="info-col-1">
          <span class="header">Fullname</span>
          <span class="text">{{ driverDetails.firstName + ' ' + driverDetails.lastName }}</span>
        </div>
        <div class="info-col-2"><span class="header">Phone number</span>
          <span class="text">{{ driverDetails.phoneNumber }}</span>
        </div>
        <div class="info-col-3"><span class="header">Email</span>
          <span class="text">{{ driverDetails.email }}</span>
        </div>
        <div class="info-col-4"><span class="header">Date of Birth</span>
          <span  class="text">{{ driverDetails.dateOfBirth }}</span>
        </div>
        <div class="info-col-5"><span class="header">Experience</span>
          <span class="text">{{ driverDetails.experience.value }}</span>
        </div>
        <div class="info-col-6"><span class="header">Address</span>
          <span class="text">{{ (driverDetails.address ? driverDetails.address + ', ' : '') + driverDetails.state.name + ', '
          + driverDetails.zipCode + ', ' + 'USA' }}</span>
        </div>
      </div>
    </div>
    <div class="company-reuqeriment">

      <RequirementItem title="Driver Type" :items="driverDetails.driverTypes.map(x => x.type)" />

      <RequirementItem title="Route Type" :items="driverDetails.routeTypes.map(x => x.type)" />

      <RequirementItem title="Freight Type" :items="driverDetails.freightTypes.map(x => x.type)" />

      <RequirementItem title="Endorsements" :items="driverDetails.endorsements.map(x => x.name)" />

    </div>
  </div>
  <div class="modals modals-delete" id="modals-delete">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit" @click="deleteDriver" data-close="modals">Yes</button>
    </div>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import RequirementItem from '../addDriverComponents/reuqDriverInfo.vue';

// services
import driverService from '@/services/driverService';


export default {
  mounted() {
    this.getDriverDetails();
  },
  components: { DashboardTitleComponent, RequirementItem },
  methods: {
    getDriverDetails: async function () {
      var response = await driverService.getDriverDetails(this.driverId);

      if (response.data.successful) {
        this.driverDetails = response.data.item;
      }
    },
    deleteDriver: async function () {
      var self = this;
      var overlay_modals = document.querySelector(".overlay");
      var response = await driverService.markLeadDeleted(self.id);
      if (response.data.successful) {

        const toast_modal = document.getElementById("modals-success");
        toast_modal.classList.add('open');
        overlay_modals.classList.remove("hidden");
        setTimeout(function () {
          toast_modal.classList.remove('open');
          overlay_modals.classList.add("hidden");
          self.$router.go();

        }, 1000);
      }
      else {
        const toast_modal = document.getElementById("modals-success");
        toast_modal.classList.add('open');
        overlay_modals.classList.remove("hidden");
        setTimeout(function () {
          toast_modal.classList.remove('open');
          overlay_modals.classList.add("hidden");
        }, 3000);
      }
    },
  },
  data() {
    return {
      // Response models
      driverDetails: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        dateOfBirth: "",
        address: "",
        city: "",
        state: {
          name: "",
        },
        experience: {
          value: ""
        },
        zipCode: "",
        driverTypes: [],
        freightTypes: [],
        routeTypes: [],
        endorsements: []
      }
    }
  },
  props: {
    driverId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>