<template>
  <div class="row_right">
    <DashboardTitleComponent title="Advertisement" />
    <div class="adver_info_row">
      <div class="adver_info_company">
        <h4>{{ advertisementDetails.subject }}</h4>
        <div class="info_content">
          <div class="offers">
            <ul>
              <li v-for="offer in advertisementDetails.offers" :key="offer.id">
                {{ offer.value }}
              </li>
            </ul>
          </div>
          <div class="adver_info_footer">
            <!--             
            <a
              href="#"
              class="footer_edit"
              data-toggle="modals"
              data-target="#modals-edit"
              >Edit</a
            > -->
          </div>
        </div>
      </div>
      <div class="adver_info_contact">
        <div class="adver_logo">
          <img
            :src="`data:image/png;base64, ${advertisementDetails.logoBase64Encoded}`"
            alt="logo"
          />
        </div>
        <div class="email">
          <img src="@/assets/images/mail.svg" alt="mail" />
          <span>{{ advertisementDetails.companyEmail }}</span>
        </div>
        <div class="phone">
          <img src="@/assets/images/phone.svg" alt="phone" />
          <span>{{ advertisementDetails.companyContactPhoneNumber }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modals modal_edit" id="modals-edit">
    <div class="header">
      <a href="№" class="close btn-close" data-close="modals">⨉</a>
    </div>
    <div class="form-block">
      <div class="form-row">
        <label for="title">Subject title</label>
        <input
          type="text"
          class="form-control"
          v-model="editAdvertisementRequestModel.modifiedSubject"
        />
      </div>
      <div class="form-row">
        <label for="description">Description</label>
        <input
          class="form-control row_form"
          style="width: 100%; height: 100%"
          v-for="(offer, index) in editAdvertisementRequestModel.modifiedOffers"
          :key="offer.id"
          type="text"
          v-model="editAdvertisementRequestModel.modifiedOffers[index].value"
        />
      </div>
    </div>
    <div class="modal_footer">
      <a href="№" class="cancel close btn-close" data-close="modals">Cancel</a>
      <a class="submit" @click="editAdvertisement" style="cursor: pointer"
        >Submit</a
      >
    </div>
  </div>
  <div class="modals modals-delete" id="modals-delete">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit" @click="rejectAdvertisement">Yes</button>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img
        :src="require(`@/assets/images/${modal.selectedIcon}`)"
        alt="Notification icon"
      />
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :is-full-page="fullPage"
    color="#26A28F"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import DashboardTitleComponent from "../shared/dashboardTitleComponent.vue";
import vacancyService from "@/services/vacancyService";
import { waitingForApprove, approved } from "@/constants/index";

export default {
  mounted() {
    this.getAdvertisementDetails();
  },
  components: {
    DashboardTitleComponent,
    Loading,
  },
  methods: {
    editAdvertisement: async function () {
      var self = this;
      var response = await vacancyService.editAdvertisement(
        self.editAdvertisementRequestModel
      );
      if (response.data.successful) {
        location.reload();
      } else {
        self.toggleModal(
          self.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0]
        );
      }
    },
    rejectAdvertisement: async function () {
      var self = this;
      var response = await vacancyService.rejectAdvertisement(
        self.advertisementId
      );
      if (response.data.successful) {
        self.toggleModal(
          self.modal.successIcon,
          "Success",
          "Advertisement has been rejected"
        );
      } else {
        self.toggleModal(
          self.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0]
        );
      }
      setTimeout(function () {
        self.$router.push({
          path: "/advertisements",
        });
      }, 3000);
    },
    getAdvertisementDetails: async function () {
      var self = this;
      var response = await vacancyService.getAdvertisementDetails(
        self.advertisementId
      );

      if (response.data.successful) {
        this.advertisementDetails = response.data.item;

        this.editAdvertisementRequestModel.advertisementId =
          this.advertisementDetails.vacancyId;
        this.editAdvertisementRequestModel.modifiedSubject =
          this.advertisementDetails.subject;

        this.advertisementDetails.offers.forEach((x) =>
          this.editAdvertisementRequestModel.modifiedOffers.push({
            id: x.id,
            value: x.value,
          })
        );

        if (!this.advertisementDetails.isReadByAdmin) {
          this.markAdvertisementRead(this.advertisementDetails.vacancyId);
        }
      }
    },
    markAdvertisementRead: async function () {
      await vacancyService.markAdvertisementRead(
        this.advertisementDetails.vacancyId
      );
    },
    approveAdvertisement: async function () {
      var self = this;
      if (self.advertisementDetails.status === waitingForApprove) {
        var response = await vacancyService.approveAdvertisement(
          self.advertisementDetails.vacancyId
        );
        if (response.data.successful) {
          self.advertisementDetails.status = approved;
          self.toggleModal(
            self.modal.successIcon,
            "Success",
            "Advertisement has been approved"
          );
        } else {
          self.toggleModal(
            self.modal.errorIcon,
            "Something went wrong",
            response.data.errors[0].messages[0]
          );
        }
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove("open");
        overlay_modals.classList.add("hidden");
      }, 3000);
    },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      editAdvertisementRequestModel: {
        advertisementId: 0,
        modifiedSubject: "",
        modifiedOffers: [],
      },
      advertisementDetails: {},
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg",
      },
    };
  },
};
</script>

<style>
.adver_logo img {
  height: 100%;
}
</style>
