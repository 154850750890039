<template>
    <section class="dashboard">
        <div class="container">
            <div class="row">
                <SidebarComponent />
                <EditVacancy />
            </div>
        </div>
    </section>
</template>

<script>
import SidebarComponent from '@/components/shared/sidebarComponent.vue';
import EditVacancy from '@/components/vacancy/editVacancy.vue';
export default {
    components: {
        SidebarComponent,
        EditVacancy
    }
}
</script>
