<template>
  <div class="plan_block">
    <!-- Choose plan -->
    <ChoosePlanCardComponet v-for="subscription in subscriptionPlans" :key="subscription.id" :id="subscription.id"
      :name="subscription.name" :isUnlimitedLeads="subscription.isUnlimitedLeads"
      :isSponsoredAdvertisement="subscription.isSponsoredAdvertisement"
      :defaultLeadsCount="subscription.defaultLeadsCount" :costPerLead="subscription.costPerLead"
      :defaultPrice="subscription.defaultPrice" :logoBase64="subscription.logoBase64" />
  </div>

  <!-- Steps -->
  <div class="plans_step">
    <div class="step">
      <span class="span-img span-img-green "><img src="@/assets/images/file-text.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 1</span><span class="step-text-desctiption">Company Details</span>
      </div>
    </div>
    <div class="step-line-choosen">
    </div>
    <div class="step">
      <span class="span-img span-img-purple "><img src="@/assets/images/award.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 2</span><span class="step-text-desctiption">Subscription</span>
      </div>
    </div>
    <div class="step-line">
    </div>
    <div class="step">
      <span class="span-img span-img-grey"><img src="@/assets/images/clipboard.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 3</span><span class="step-text-desctiption">Hiring requirements</span>
      </div>
    </div>
    <div class="step-line">
    </div>
    <div class="step">
      <span class="span-img span-img-grey "><img src="@/assets/images/credit-card.svg" alt="step"></span>
      <div class="step_text">
        <span class="step-text-header">STEP 4</span><span class="step-text-desctiption">Payment</span>
      </div>
    </div>
  </div>

</template>

<script>
import ChoosePlanCardComponet from './companyChoosePlanCardComponent/choosePlanCardComponet.vue';

// Services
import subscriptionService from '@/services/subscriptionService';

export default {
  mounted() {
    this.getSubscriptionPlans();
  },
  components: {
    ChoosePlanCardComponet
  },
  methods: {
    getSubscriptionPlans: async function () {
      var response = await subscriptionService.getSubscriptionPlans();

      if (response.data.successful) {
        this.subscriptionPlans = response.data.items;
      } else {
        alert(response.data.errors[0].messages[0]);
      }
    }
  },
  data() {
    return {
      // Response models
      subscriptionPlans: []
    }
  }
}
</script>

<style >

</style>