import axios from "axios";
import errorHandler from "@/helpers/errorHandler";

export default {
    contactUs: async function (carrierRegisterModel) {
        var instance = axios.create({
            baseURL: window.config.baseApiUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        var response = {};

        await instance.post("/api/carrier/contactUs", carrierRegisterModel)
        .then(function(res) {
           response = res;
        })
        .catch(async (error) => {
            response = await errorHandler.handleRequestError(error);
        });
        return response;
    }
}