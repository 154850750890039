<template>
    <section class="login">
        <div class="container">
            <div class="row">
                <div class="row_left">
                    <a href="/" class="logo">
                        <img src="@/assets/images/logo.svg" alt="Logo FindCDL">
                    </a>
                    <div class="form_block">
                        <div class="row_form">
                            <span class="with_border_text">Set password</span>
                        </div>

                        <!-- Password -->
                        <div class="row_form">
                            <div class="col_form ">
                                <label class="form-label"
                                    :class="{ 'error_class': v$.setPasswordRequestModel.password.$error }">
                                    Password
                                    <span>*</span>
                                </label>
                                <input type="password" placeholder="Enter password" class="form-control"
                                    v-model="setPasswordRequestModel.password"
                                    @blur="v$.setPasswordRequestModel.password.$touch"
                                    :class="{ error_border: v$.setPasswordRequestModel.password.$error }">
                                <div class="error_class" v-if="v$.setPasswordRequestModel.password.$error">
                                    {{ v$.setPasswordRequestModel.password.$errors[0].$message }}
                                </div>
                            </div>
                        </div>

                        <!-- Password confirm -->
                        <div class="row_form">
                            <div class="col_form password">
                                <label class="form-label"
                                    :class="{ 'error_class': v$.setPasswordRequestModel.passwordConfirm.$error }">
                                    Password confirm
                                    <span>*</span>
                                </label>
                                <div class="password_field">
                                    <input type="password" placeholder="Enter password confirm" class="form-control"
                                        v-model="setPasswordRequestModel.passwordConfirm"
                                        @blur="v$.setPasswordRequestModel.passwordConfirm.$touch"
                                        :class="{ error_border: v$.setPasswordRequestModel.passwordConfirm.$error }">
                                </div>
                                <div class="error_class" v-if="v$.setPasswordRequestModel.passwordConfirm.$error">
                                    {{ v$.setPasswordRequestModel.passwordConfirm.$errors[0].$message }}
                                </div>
                            </div>
                        </div>

                        <div class="row_bottom">
                            <button class="button" :class="{ 'but_green': !this.v$.$invalid }" @click="setPassword"
                                :disabled="this.v$.$invalid">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row_right">
                    <h1 class="banner_text">Welcome</h1>
                    <span class="banner_innertext">This dashboard belongs to company findcdl.com</span>
                </div>
            </div>
        </div>
    </section>
    <div class="overlay hidden"></div>

    <!-- Modal notification -->
    <div class="modals modal_notification" id="modal">
        <div class="content_group">
            <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
            <div class="modal_content">
                <span class="not_title">{{ modal.title }}</span>
                <span class="modal_text">{{ modal.body }}</span>
            </div>
        </div>
        <a href="#" class="close btn-close" data-close="modals">⨉</a>
    </div>
</template>
<script>

import companyService from '@/services/companyService';
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { validateEmail } from '@/services/validationService';

export default {
    setup: () => ({
        v$: useValidate()
    }),
    mounted() {
        var self = this;
        self.extractQueryParams();
    },
    methods: {
        extractQueryParams: function () {
            var self = this;
            let email = self.getQueryParams("email");
            let uniqueId = self.getQueryParams("uniqueId");
            if (!email || !uniqueId) {
                self.toggleModal(self.modal.errorIcon, "Something went wrong", "Invalid email or unique id");
            }
            else {
                self.setPasswordRequestModel.email = email;
                self.setPasswordRequestModel.uniqueId = uniqueId;
            }
        },
        getQueryParams: function (paramName, url = window.location.href) {
            paramName = paramName.replace(/[[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + paramName + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
            if (!results)
                return null;
            if (!results[2])
                return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        setPassword: async function () {
            var self = this;
            var response = await companyService.setPassword(self.setPasswordRequestModel);
            if (response.data.successful) {
                self.toggleModal(self.modal.successIcon, "Success", "Password has been set");
                setTimeout(() => {
                    self.$router.push({
                        path: "/login"
                    })
                }, 3000);
            } else {
                self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
            }
        },
        toggleModal: function (imgSrc, title, body) {
            var self = this;
            self.modal.selectedIcon = imgSrc;
            self.modal.title = title;
            self.modal.body = body;

            var overlay_modals = document.querySelector(".overlay");
            const toast_modal = document.getElementById("modal");
            toast_modal.classList.add('open');
            overlay_modals.classList.remove("hidden");
            setTimeout(function () {
                toast_modal.classList.remove('open');
                overlay_modals.classList.add("hidden");
            }, 3000);
        }
    },
    data() {
        return {
            setPasswordRequestModel: {
                email: "",
                uniqueId: "",
                password: "",
                passwordConfirm: ""
            },
            modal: {
                title: "",
                body: "",
                successIcon: "success_icon.svg",
                warningIcon: "warning_icon.svg",
                errorIcon: "error_icon.svg",
                selectedIcon: "success_icon.svg"
            }
        };
    },
    validations() {
        return {
            setPasswordRequestModel: {
                email: {
                    required: helpers.withMessage("Email is required", required),
                    validateEmail: helpers.withMessage("Email is invalid", validateEmail)
                },
                uniqueId: {
                    required: helpers.withMessage("Unique id is required", required)
                },
                password: {
                    required: helpers.withMessage("Password is required", required)
                },
                passwordConfirm: {
                    required: helpers.withMessage("Password confirm is required", required)
                }
            }
        }
    }
}
</script>
