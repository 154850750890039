import authService from "./authService";
import errorHandler from "@/helpers/errorHandler";

export default {
    getCurrentUserProfileData: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get('/api/user/getProfileData')
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    updateProfile: async function (formData) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post('/api/user/updateProfile', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getUsers: async function (page, size, searchKey) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get(`/api/user/getUsers?page=${page}&size=${size}&firstNameOrLastNameSearchKey=${searchKey}`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getUserById: async function (userId) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get(`/api/user/getUser?userId=${userId}`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    editUser: async function (editUserRequestModel) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post('/api/user/editUser', editUserRequestModel)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    deleteUser: async function (userId) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post(`/api/user/deleteUser?userId=${userId}`)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    }
}
