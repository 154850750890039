<template>
    <section class="plans_content">
      <div class="container">
        <h2>Choose Your plan</h2>
      </div>
      <div class="container">
        <router-link class="btn-to-drivers" to="/dashboard">
          <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
        </router-link>
      </div>
      <div class="container">
        <CompanyChangePlanComponent />
      </div>
    </section>
    <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
  </template>
  
  <script>
  
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import CompanyChangePlanComponent from '@/components/companyChangePlanComponents/companyChangePlanComponent.vue';
  
  
  
  
  export default {
    mounted() {
      this.isPageLoading();
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
      }
    },
    methods: {
      isPageLoading() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      },
    },
    components: { CompanyChangePlanComponent, Loading }
  }
  </script>
  
  <style >
  .plans_content .btn-to-drivers {
    margin-top: 41px;
  }
  </style>