<template>
  <div class="row_left">
    <router-link to="/dashboard">
      <img src="@/assets/images/logo.svg" alt="Logo CDL" />
    </router-link>
    <div class="row_menu">
      <router-link class="but_white" to="/dashboard">
        <img src="@/assets/images/home.svg" alt="Dashboard" />
        Dashboard
      </router-link>

      <router-link class="but_white" to="/leads">
        <img src="@/assets/images/user.svg" alt="Drivers" />
        Leads
      </router-link>

      <router-link class="but_white" to="/hiring-requirments">
        <img src="@/assets/images/file-text_dash.svg" alt="File" /> Hiring
        requirements
      </router-link>

      <router-link v-if="isPremium" class="but_white" to="/advertisements">
        <img src="@/assets/images/bookmark.svg" alt="File" /> Featured Job Ads
      </router-link>
    </div>
    <div class="row_bottom">
      <a href="#" class="log_out" data-toggle="modals" data-target="#modals-1">
        <span class="out_icon">
          <img src="@/assets/images/log-out.svg" alt="log-out" />
        </span>
        <span class="dashboard_out">Log Out</span>
      </a>
    </div>
  </div>

  <div class="modals modal_logout" id="modals-1">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit" @click="logout()">Yes</button>
    </div>
  </div>
  <div class="overlay hidden"></div>
</template>

<script>
import SubscriptionService from "@/services/subscriptionService";

export default {
  mounted() {
    this.getPlan();
    document.addEventListener("click", function (e) {
      const overlay_modals = document.querySelector(".overlay");

      let $target = e.target;
      if ($target.closest('[data-toggle="modals"]')) {
        e.preventDefault();
        $target = $target.closest('[data-toggle="modals"]');
        document.querySelector($target.dataset.target).classList.add("open");
        overlay_modals.setAttribute(
          "data-closeoverlay",
          $target.dataset.target
        );
        overlay_modals.classList.remove("hidden");
      } else if ($target.dataset.close === "modals") {
        e.preventDefault();

        $target.closest(".modals").classList.remove("open");

        overlay_modals.classList.add("hidden");
      } else if ($target.dataset.closeoverlay != null) {
        e.preventDefault();
        document
          .querySelector($target.dataset.closeoverlay)
          .classList.remove("open");
        overlay_modals.classList.add("hidden");
        $target.dataset.closeoverlay = null;
      }
    });
  },
  methods: {
    logout: function () {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    getPlan: async function () {
      var self = this;
      var response = await SubscriptionService.getSubscriptionPlan();
      if (response && response.data.successful) {
        self.isPremium = response.data.item.isSponsoredAdvertisement;
      }
    },
  },
  data: function () {
    return {
      isPremium: false,
    };
  },
};
</script>

<style></style>
