<template>
  <div class="plan_card" :class="{ premium_class: isSponsoredAdvertisement }">
    <div class="plan_top">
      <div class="plan_name">
        <img :src="logoBase64" alt="">
        <span>{{ name }}</span>
      </div>
      <div class="plan_chances">
        <span class="chance">
          <img :src="require(`@/assets/images/x.svg`)" alt="" v-if="!isUnlimitedLeads">
          <img :src="require(`@/assets/images/chance_check.svg`)" alt="" v-if="isUnlimitedLeads">
          <p>Unlimited Leads</p>
        </span>
        <span class="chance">
          <img :src="require(`@/assets/images/x.svg`)" alt="" v-if="!isSponsoredAdvertisement">
          <img :src="require(`@/assets/images/chance_check.svg`)" alt="" v-if="isSponsoredAdvertisement">
          <p>Sponsored Advertisement</p>
        </span>
        <span class="chance" v-if="!isUnlimitedLeads">
          <img :src="require(`@/assets/images/chance_check.svg`)">
          <p>Per lead ${{ costPerLead }} </p>
        </span>
      </div>
    </div>
    <div class="plan_bottom">
      <span>
        <!-- <p>{{ defaultPrice }}</p>/month -->
        <p> {{ isUnlimitedLeads? '$' + defaultPrice + ' per month':  '$' + costPerLead * defaultLeadsCount }}</p>
      </span>
      <a class="button" @click="choosePlan" style="cursor: pointer;">Choose Plan</a>
    </div>
  </div>
</template>

<script>

import subscriptionService from '@/services/subscriptionService';

export default {
  methods: {
    choosePlan: async function () {
      var self = this;
      var response = await subscriptionService.choosePlan(self.id);

      if (response.data.successful) {
        this.$router.push({
          path: '/requirement'
        })
      } else {
        alert(response.data.errors[0].messages[0]);
      }
    }
  },
  props: {
    id: null,
    name: null,
    isUnlimitedLeads: null,
    isSponsoredAdvertisement: null,
    defaultLeadsCount: null,
    costPerLead: null,
    defaultPrice: null,
    logoBase64: null
  }
}
</script>

<style>

</style>