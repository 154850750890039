import { createStore } from 'vuex'

export default createStore({
  state: {
    companyAuthData: {
      isAuthenticated: false,
      accessToken: null,
      accessTokenExpiry: null,
      id: null,
      email: null,
      phoneNumber: null,
      role: null
    },
    logo: null,
    isSubscriptionActive: false
  },
  getters: {
    companyAccessToken: (state) => state.companyAuthData.accessToken,
    companyAccessTokenExpiry: (state) => state.companyAuthData.accessTokenExpiry,
    adminId: (state) => state.companyAuthData.id,
    companyEmail: (state) => state.companyAuthData.email,
    companyPhoneNumber: (state) => state.companyAuthData.phoneNumber,
    companyRole: (state) => state.companyAuthData.role,
    isAuthenticated: (state) => state.companyAuthData.isAuthenticated,
    logoBase64Encoded: (state) => state.logo,
    isSubscriptionActive: (state) => state.isSubscriptionActive,
  },
  mutations: {
    initializeState(state) {
      if (localStorage.getItem('state')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('state')))
        );
        // console.log("=> State successfully initialized from local storage");
      }
    },
    authenticateCompany(state, { companyAuthData }) {
      state.companyAuthData = companyAuthData;
      state.companyAuthData.isAuthenticated = true;
      localStorage.setItem('state', JSON.stringify(state));

      // console.log("=> Company has been authenticated");
      // console.log("=> State saved (updated) to local storage");
    },
    logout(state) {
      state.companyAuthData.isAuthenticated = false;
      state.companyAuthData.accessToken = null;
      state.companyAuthData.accessTokenExpiry = null;
      state.companyAuthData.id = null;
      state.companyAuthData.email = null;
      state.companyAuthData.phoneNumber = null;
      state.companyAuthData.role = null;
      
      state.isSubscriptionActive = false;

      localStorage.setItem('state', JSON.stringify(state));
      // console.log("=> Super admin has been logged out and state has been updated");
    },
    setlogo(state, {logo}) {
      state.logo = logo;
    },
    setStatus(state, {status}){
      state.isSubscriptionActive = status;
      localStorage.setItem('state', JSON.stringify(state));
    }
  },
  actions: {
  },
  modules: {
  }
})
