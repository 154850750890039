import errorHandler from "@/helpers/errorHandler";
import authService from '@/services/authService';

export default {
    getDrivers: async function (requestModel, page, size) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        var url = `/api/lead/getLeads?page=${page}&size=${size}&name=${requestModel.name}&experienceId=${requestModel.experienceId}&currentCompanyId=${requestModel.currentCompanyId}`;

        requestModel.driverTypeIds.forEach(x => {
            url += `&driverTypeIds=${x}`
        });

        requestModel.freightTypeIds.forEach(x => {
            url += `&freightTypeIds=${x}`
        });

        requestModel.routeTypeIds.forEach(x => {
            url += `&routeTypeIds=${x}`
        });

        requestModel.endorsementIds.forEach(x => {
            url += `&endorsementTypeIds=${x}`
        });

        await instance.get(url)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    createDriverAsAdmin: async function (createDriverRequestModel) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};
        await instance
            .post("api/driver/CreateDriverAsAdmin", createDriverRequestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                console.log(error);
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    getDriverDetails: async function (driverId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/lead/getLead?driverId=${driverId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getReceviedLeadsToday: async function () {

        var instance = await authService.getAuthorizedRequest();
        var response = {};
        var url = `/api/lead/getTodayReceviedLeadsCount`;

        await instance.get(url)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    markLeadDeleted: async function (id) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};
        await instance
            .post(`/api/lead/markDeleted?leadId=${id}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                console.log(error);
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
}