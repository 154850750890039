import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/loginView.vue'
import DashboardView from '../views/dashboardView.vue'
import CarrierRegisterView from '../views/carrierRegisterView.vue'
import LeadsView from '../views/leadsView.vue'
import CompanyChangePlan from '../views/companyChangePlanView.vue'
import HiringRequirmentsView from '../views/hiringRequirmentsView.vue'
import DriverInfoView from '../views/leadsInfoView.vue'
import UsersView from '../views/usersView.vue'
import CreateUserView from '../views/createUserView.vue'
import ProfileView from '../views/profileView.vue';
import EditCompanyView from '../views/editCompanyView.vue';
import setPasswordView from '@/views/setPasswordView.vue'
import EditVacancyView from '@/views/vacancy/editVacancyView.vue';
// Plan Components view
import CompanyDetailsView from '../views/companyDetailsView.vue'
import CompanyChoosePlan from '../views/companyChoosePlanView.vue'
import RequirementView from '../views/requirementView.vue'
import PaymentView from '../views/paymentView.vue'
import AdvertisementView from '../views/advertisementView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/leads',
    name: 'leads',
    component: LeadsView
  },
  {
    path: '/hiring-requirments',
    name: 'hiring ',
    component: HiringRequirmentsView
  },

  {
    path: '/driver-info/:driverId',
    name: 'info driver',
    component: DriverInfoView,
    props: true
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  }
  ,
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  }
  ,
  {
    path: '/edit-company',
    name: 'edit company',
    component: EditCompanyView
  }
  ,
  {
    path: '/create-user',
    name: 'create user',
    component: CreateUserView
  },
  {
    path: "/company-details",
    name: "company details",
    component: CompanyDetailsView
  },
  {
    path: "/choose-plan",
    name: "choose plan",
    component: CompanyChoosePlan
  },
  {
    path: "/change-plan",
    name: "change plan",
    component: CompanyChangePlan
  },
  {
    path: "/requirement",
    name: "reuirement",
    component: RequirementView,
  },
  {
    path: '/payment',
    name: "payment",
    component: PaymentView
  },
  {
    path: '/set-password',
    name: 'set password',
    component: setPasswordView
  },
  {
    path: '/edit-requirements',
    name: 'edit requirements',
    component: EditVacancyView
  },
  {
    path: '/advertisements',
    name: 'Advertisements',
    component: AdvertisementView
  },
  {
    path: '/carrier-register',
    name: 'Carrier Register',
    component: CarrierRegisterView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
