<template>
  <div class="container driver_add_main">
    <div class="form_block form_chek_radio">
      <div class="row_form bot_divider">
        <div class="col_form state_form">
          <div class="col_title">
            State
            <span>*</span>
          </div>
          <Multiselect
            mode="single"
            valueProp="id"
            group-values="name"
            track-by="name"
            label="name"
            :options="states"
            v-model="createRequirementsRequestModel.stateId"
            :searchable="true"
            :caret="true"
            :hide-selected="false"
            :close-on-select="true"
            noOptionsText="City not selected"
            noResultsText="No state found!"
            placeholder="Washington, Seattle...."
            @blur="v$.createRequirementsRequestModel.state.touch()"
            :class="{
              error_border: v$.createRequirementsRequestModel.stateId.$error,
            }"
          />
          <div
            class="error_class"
            v-if="v$.createRequirementsRequestModel.stateId.$error"
          >
            {{ v$.createRequirementsRequestModel.stateId.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="row_form bot_divider">
        <div class="col_form">
          <div class="col_title">Driver Type <span>*</span></div>
          <div
            v-for="driverType in driverTypes"
            :key="driverType.id"
            class="form-check"
          >
            <input
              class="form-styled checkbox_styled"
              type="checkbox"
              :value="driverType.id"
              :id="`driverType_${driverType.id}`"
              v-model="createRequirementsRequestModel.driverTypeIds"
            />
            <label :for="`driverType_${driverType.id}`">
              {{ driverType.type }}
            </label>
          </div>
        </div>
        <div class="col_form">
          <div class="col_title">Experience <span>*</span></div>

          <div v-for="exp in experiences" :key="exp.id" class="form-check">
            <input
              class="form-styled radio_styled"
              type="radio"
              name="radioGroup_1"
              :id="`Radio_${exp.id}`"
              :value="exp.id"
              v-model="createRequirementsRequestModel.experienceId"
            />
            <label :for="`Radio_${exp.id}`">
              {{ exp.value }}
            </label>
          </div>
        </div>
        <div class="col_form">
          <div class="col_title">Route Type <span>*</span></div>
          <div
            v-for="routeType in routeTypes"
            :key="routeType.id"
            class="form-check"
          >
            <input
              class="form-styled checkbox_styled"
              type="checkbox"
              :value="routeType.id"
              :id="`routeType_${routeType.id}`"
              v-model="createRequirementsRequestModel.routeTypeIds"
            />
            <label :for="`routeType_${routeType.id}`">
              {{ routeType.type }}
            </label>
          </div>
        </div>
      </div>
      <div class="row_form">
        <div class="col_form">
          <div class="col_title">Freight Type <span>*</span></div>
          <div
            v-for="freightType in freightTypes"
            :key="freightType.id"
            class="form-check"
          >
            <input
              class="form-styled checkbox_styled"
              type="checkbox"
              :value="freightType.id"
              :id="`freightType_${freightType.id}`"
              v-model="createRequirementsRequestModel.freightTypeIds"
            />
            <label :for="`freightType_${freightType.id}`">
              {{ freightType.type }}
            </label>
          </div>
        </div>
        <div class="col_form">
          <div class="col_title">Endorsements</div>
          <div v-for="end in endorsements" :key="end.id" class="form-check">
            <input
              class="form-styled checkbox_styled"
              type="checkbox"
              :value="end.id"
              :id="`endorsement_${end.id}`"
              v-model="createRequirementsRequestModel.endorsementIds"
            />
            <label :for="`endorsement_${end.id}`">
              {{ end.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="plans_step">
      <div class="step">
        <span class="span-img span-img-green"
          ><img src="@/assets/images/file-text.svg" alt="step"
        /></span>
        <div class="step_text">
          <span class="step-text-header">STEP 1</span
          ><span class="step-text-desctiption">Company Details</span>
        </div>
      </div>
      <div class="step-line-choosen"></div>
      <div class="step">
        <span class="span-img span-img-green"
          ><img src="@/assets/images/award.svg" alt="step"
        /></span>
        <div class="step_text">
          <span class="step-text-header">STEP 2</span
          ><span class="step-text-desctiption">Subscription</span>
        </div>
      </div>
      <div class="step-line-choosen"></div>
      <div class="step">
        <span class="span-img span-img-purple"
          ><img src="@/assets/images/clipboard.svg" alt="step"
        /></span>
        <div class="step_text">
          <span class="step-text-header">STEP 3</span
          ><span class="step-text-desctiption">Hiring requirements</span>
        </div>
      </div>
      <div class="step-line"></div>
      <div class="step">
        <span class="span-img span-img-grey"
          ><img src="@/assets/images/credit-card.svg" alt="step"
        /></span>
        <div class="step_text">
          <span class="step-text-header">STEP 4</span
          ><span class="step-text-desctiption">Payment</span>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="requirement-btn-content">
      <a
        class="button but_green"
        style="cursor: pointer"
        @click="createRequirements"
        >Submit</a
      >
    </div>
  </div>

  <div class="overlay hidden"></div>
  <!-- Modal notification -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img
        :src="require(`@/assets/images/${modal.selectedIcon}`)"
        alt="Notification icon"
      />
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a class="close btn-close" data-close="modals">⨉</a>
  </div>
</template>

<script>
import driverTypeService from "@/services/driverTypeService";
import experienceService from "@/services/experienceService";
import routeTypeService from "@/services/routeTypeService";
import freightTypeService from "@/services/freightTypeService";
import endorsementService from "@/services/endorsementService";
import StateService from "@/services/stateService";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import vacancyService from "@/services/vacancyService";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    this.getDriverTypes();
    this.getExperiences();
    this.getRouteTypes();
    this.getFreightTypes();
    this.getEndorsements();
    this.getStates();
    this.loadHiringRequirements();
  },
  components: {
    Multiselect,
  },
  methods: {
    loadHiringRequirements: async function () {
      var response = await vacancyService.getVacancyRequirements();
      if (response.data.successful && response.data.item) {
        var requirements = response.data.item;

        this.createRequirementsRequestModel.vacancyId = requirements.vacancyId;
        this.createRequirementsRequestModel.stateId = requirements.state.id;
        this.createRequirementsRequestModel.experienceId =
          requirements.experience.id;

        this.createRequirementsRequestModel.driverTypeIds = [];
        requirements.driverTypes.forEach((x) =>
          this.createRequirementsRequestModel.driverTypeIds.push(x.id)
        );

        this.createRequirementsRequestModel.freightTypeIds = [];
        requirements.freightTypes.forEach((x) =>
          this.createRequirementsRequestModel.freightTypeIds.push(x.id)
        );

        this.createRequirementsRequestModel.routeTypeIds = [];
        requirements.routeTypes.forEach((x) =>
          this.createRequirementsRequestModel.routeTypeIds.push(x.id)
        );

        this.createRequirementsRequestModel.endorsementIds = [];
        requirements.endorsements.forEach((x) =>
          this.createRequirementsRequestModel.endorsementIds.push(x.id)
        );
      }
    },
    getDriverTypes: async function () {
      var response = await driverTypeService.getDriverTypes();
      if (response.data.successful) {
        this.driverTypes = response.data.items;
      }
    },
    getExperiences: async function () {
      var response = await experienceService.getExperiences();
      if (response.data.successful) {
        this.experiences = response.data.items;
      }
    },
    getRouteTypes: async function () {
      var response = await routeTypeService.getRouteTypes();
      if (response.data.successful) {
        this.routeTypes = response.data.items;
      }
    },
    getFreightTypes: async function () {
      var response = await freightTypeService.getFreightTypes();
      if (response.data.successful) {
        this.freightTypes = response.data.items;
      }
    },
    getEndorsements: async function () {
      var response = await endorsementService.getEndorsements();
      if (response.data.successful) {
        this.endorsements = response.data.items;
      }
    },
    getStates: async function () {
      var response = await StateService.getStates();
      if (response.data.successful) {
        this.states = response.data.items;
      }
    },
    createRequirements: async function () {
      var self = this;
      if (this.v$.$invalid) {
        this.toggleModal(
          this.modal.errorIcon,
          "Validation Error",
          "Please fill out the form properly"
        );
        return;
      }
      var response = {};
      if (self.createRequirementsRequestModel.vacancyId) {
        response = await vacancyService.editVacancy(
          self.createRequirementsRequestModel
        );
        if (response.data.successful) {
          self.loadHiringRequirements();
          self.toggleModal(
            self.modal.successIcon,
            "Success",
            "Hiring requirements have been edited"
          );
          setTimeout(() => {
            self.$router.push({
              path: "/payment",
            });
          }, 3000);
        } else {
          self.toggleModal(
            self.modal.errorIcon,
            "Something went wrong",
            response.data.errors[0].messages[0]
          );
        }
      } else {
        response = await vacancyService.createVacancy(
          self.createRequirementsRequestModel
        );
        if (response.data.successful) {
          self.loadHiringRequirements();
          self.toggleModal(
            self.modal.successIcon,
            "Success",
            "Hiring requirements have been created"
          );
          setTimeout(() => {
            self.$router.push({
              path: "/payment",
            });
          }, 3000);
        } else {
          self.toggleModal(
            self.modal.errorIcon,
            "Something went wrong",
            response.data.errors[0].messages[0]
          );
        }
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove("open");
        overlay_modals.classList.add("hidden");
      }, 3000);
    },
  },
  data() {
    return {
      driverTypes: [],
      experiences: [],
      routeTypes: [],
      freightTypes: [],
      endorsements: [],
      states: [],
      createRequirementsRequestModel: {
        driverTypeIds: [],
        experienceId: [],
        routeTypeIds: [],
        freightTypeIds: [],
        endorsementIds: [],
        stateId: null,
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg",
      },
    };
  },
  validations() {
    return {
      createRequirementsRequestModel: {
        experienceId: {
          required,
        },
        driverTypeIds: {
          required,
          minLength: minLength(1),
        },
        freightTypeIds: {
          required,
          minLength: minLength(1),
        },
        routeTypeIds: {
          required,
          minLength: minLength(1),
        },
        stateId: {
          required: helpers.withMessage("State is required", required),
        },
      },
    };
  },
};
</script>
