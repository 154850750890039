// Claim names
export const idClaimName = "sub";
export const emailClaimName = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
export const phoneNumberClaimName = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone";
export const roleClaimName = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const jwtTokenExpClaimName = "exp";

// Role names
export const adminRoleName = "Admin";
export const carrierRoleName = "Carrier";
export const driverRoleName = "Driver";

// Carrier subscription plans
export const basicPlanName = "Basic";
export const silverPlanName = "Standard";
export const premiumPlanName = "Premium";

// Advertisement status
export const approved = "Approved";
export const waitingForApprove = "WaitingForApprove";

export const imageFileAllowedExtensions = ["png", "jpg", "jpeg"]