<template>
  <section class="plans_content">
    <div class="container">
      <h2>Company details</h2>
    </div>
    <div class="company_add_main">
      <div class="container">
        <CompanyDetailsComponent />
      </div>
    </div>
  </section>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import CompanyDetailsComponent from '@/components/companyChoosingPlanComponents/companyDetailsComponent.vue';


export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: { CompanyDetailsComponent, Loading }
}
</script>

<style >

</style>