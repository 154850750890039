<template>
  <LoginComponent />
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import LoginComponent from '@/components/loginComponents/loginComponent.vue';

export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  },
  components: {
    LoginComponent,
    Loading
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 700)
    },
  },
}
</script>

<style >

</style>