<template>
  <div class="row_right">
    <DashboardTitleComponent title="Company Profile" />

    <div class="edit_company_content">
      <router-link to="/edit-company" class="edit_company_button">Edit details</router-link>
    </div>

    <div class="company-info">
      <div class="company-logo">
        <img :src="`data:image/png;base64,${ companyDetails.logoBase64Encoded }`" />
      </div>
      <div class="company-contact">
        <h2 class="company-name">{{ companyDetails.companyName }}</h2>
        <p class="contact-title">{{ companyDetails.contacteeName }}</p>
        <div class="contacts">
          <ul class="responsive-table contacts-table">
            <li class="table-header contact-data">
              <div class="col col-1">Firstname</div>
              <div class="col col-2">Lastname</div>
              <div class="col col-3">Email</div>
              <div class="col col-4">Phone</div>
            </li>
            <li class="table-row contact-data">
              <div class="col col-1 "><span>{{ userDetails.firstName }}</span>
              </div>
              <div class="col col-2"><span>{{ userDetails.lastName }}</span>
              </div>
              <div class="col col-3"><span>{{ companyDetails.email }}</span></div>
              <div class="col col-4"><span>{{ companyDetails.phoneNumber }}</span></div>
            </li>
          </ul>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

// services
import companyService from '@/services/companyService';
import userService from '@/services/userService';

export default {
  mounted() {
    this.getCompanyDetails();
    this.getCurrentUserProfileData();

  },
  components: { DashboardTitleComponent },
  methods: {
    getCompanyDetails: async function () {
      var self = this;
      var response = await companyService.getCompanyDetailsWithoutCompanyId();

      if (response.data.successful) {
        self.companyDetails = response.data.item;
      }
    },
    getCurrentUserProfileData: async function ()  {
      var self = this;
      var response = await userService.getCurrentUserProfileData();
      if (response.data.successful) {
        self.userDetails = response.data.item;
      }

    }
  },
  data() {
    return {
      // Response models
      companyDetails: {
        companyId: 0,
        companyName: '',
        contacteeName: '',
        email: '',
        logoBase64Encoded: '',
        subscriptionPlanLogoBase64Encoded: '',
        phoneNumber: '',
        subscription: ''
      },
      userDetails: {
        firstName: '',
        lastName: ''
      }
    }
  }

}
</script>


<style >

</style>