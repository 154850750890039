import authService from "@/services/authService";
import errorHandler from "@/helpers/errorHandler";
import axios from "axios";

export default {
    getCompanies: async function (getCompaniesRequestModel, page, size) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        var url = `/api/carrier/getCompanies?page=${page}&size=${size}`;

        if (getCompaniesRequestModel.companyName) {
            url += `&companyName=${getCompaniesRequestModel.companyName}`;
        }

        if (getCompaniesRequestModel.subscriptionPlan) {
            url += `&subscriptionPlan=${getCompaniesRequestModel.subscriptionPlan}`;
        }

        await instance.get(url)
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getCompanyDetails: async function (companyId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/carrier/getCompanyDetails?companyId=${companyId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    getCompanyRequests: async function (page, size) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/carrier/getCopmanyRequests?page=${page}&size=${size}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = errorHandler.handleRequestError(error);
            });
        return response;
    },
    getCompanyRequestById: async function (getCompanyRequestId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/carrier/getCompanyRequest?companyRequestId=${getCompanyRequestId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = errorHandler.handleRequestError(error);
            });
        return response;
    },
    markCopmanyRequestAsRead: async function (companyRequestId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/carrier/markCopmanyRequestAsRead?companyRequestId=${companyRequestId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = errorHandler.handleRequestError(error);
            });
        return response;
    },
    setPassword: async function (setPasswordRequestModel) {
        var instance = axios.create({
            baseURL: window.config.baseApiUrl,
            headers: {
                "Content-Type": "application/json"
            }
        });

        var response = {};

        await instance.post('/api/carrier/setPassword', setPasswordRequestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });

        return response;
    },
    respondToCompanyRequest: async function (requestModel) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/carrier/respondToCompanyRequest`, requestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = errorHandler.handleRequestError(error);
            });
        return response;
    },
    editCompanyDetails: async function (companyRequestDto) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post('/api/carrier/editCompanyDetails', companyRequestDto, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((res) => {
                response = res;
            })
            .catch(async function (error) {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getCompanyDetailsWithoutCompanyId: async function () {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/carrier/getCompanyDetailsWithoutCompanyId`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
}