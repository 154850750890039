<template>
  <section class="plans_content">
    <div class="container">
      <h2>Hiring Requirements</h2>
    </div>
    <div class="container">
      <router-link class="btn-to-drivers" to="/choose-plan">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left" />
        <span>Back</span>
      </router-link>
    </div>

    <RequirementComponent />
  </section>
  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :is-full-page="fullPage"
    color="#26A28F"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import RequirementComponent from "@/components/companyChoosingPlanComponents/requirementComponent.vue";

export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
  components: { RequirementComponent, Loading },
};
</script>

<style></style>
